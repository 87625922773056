import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

// Styles
import '../styles/IconTextComponent.styles.css';

const IconTextComponent = ({
	icon,
	text,
	dates = null,
	label = null,
	dropShadow = false,
	isCapitalized = true,
	isCentered = true,
	textStyles = {},
	...props
}) => {
	const { lang } = useParams();

	return (
		<span
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang === 'en' ? 'en' : 'ar'}
			className={`icon-text d-flex ${
				isCentered ? 'align-items-center' : ''
			} py-1`}
			{...props}
		>
			{/* Icon */}
			<span
				className={lang === 'en' ? 'me-1' : 'ms-1'}
				style={{
					filter: dropShadow ? 'drop-shadow(1px 1px 0 #000000)' : '',
				}}
			>
				{icon}
			</span>

			{/* Main Label */}
			{label && (
				<span
					className={`${isCapitalized ? 'text-capitalize' : ''} ${
						lang === 'en' ? 'ps-1' : 'pe-1'
					}`}
					style={{
						minWidth: '8rem',
						fontWeight: '500',
					}}
				>{`${label}:`}</span>
			)}

			{/* Main Text */}
			{!dates ? (
				<span
					className={`${isCapitalized ? 'text-capitalize' : ''}`}
					style={textStyles}
				>
					{text}
				</span>
			) : (
				<>
					{dates.start}
					{lang === 'en' ? (
						<FaArrowRight className='mx-2' />
					) : (
						<FaArrowLeft className='mx-2' />
					)}
					{dates.end}
				</>
			)}
		</span>
	);
};

export default IconTextComponent;

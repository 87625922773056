import { createSlice } from '@reduxjs/toolkit';

// Slice

export const monthsSlice = createSlice({
	name: 'months',
	initialState: {
		months: [],
		isMonthsLoading: true,
	},
	reducers: {
		monthsSuccess: (state, action) => {
			state.months = action.payload;
			state.isMonthsLoading = false;
		},

		startLoading: (state) => {
			state.isMonthsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isMonthsLoading = false;
		},
	},
});

export default monthsSlice.reducer;

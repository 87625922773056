import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ar';
import React, { useEffect, useState } from 'react';
import { Flip } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { constructURL } from '../helpers/constructURL';

// Styles
import '../styles/CategoryDetailsPage.styles.css';

// Components
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import CourseCardComponent from '../components/CourseCardComponent';
import LoadingPageComponent from '../components/LoadingPageComponent';

const CategoryDetailsPage = ({ locale }) => {
	locale ? moment.locale('en') : moment.locale('ar');

	// i18n
	const { lang, section_id, category_id } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	const [category, setCategory] = useState(null);

	// Fetch Course Data
	const getCategoryData = async (language = 'en') => {
		axios
			.get(constructURL(`categories/${category_id}`), {
				headers: { locale: language },
			})
			.then((response) => {
				setCategory(response.data.data);
			});
	};

	// Render Initial Category
	useEffect(() => {
		setCategory(null);
		getCategoryData(locale ? 'en' : 'ar');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	const breadcrumbItems = category && [
		{
			title: t('breadcrumb.home', { ns: 'words' }),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: category.section.title,
			href: `/${lang}/section/${section_id}`,
			isActive: false,
		},
		{
			title: t('breadcrumb.availableCourses', { ns: 'words' }),
			href: '',
			isActive: true,
		},
	];

	return (
		<section
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			id={`section-${section_id}`}
			style={{
				minHeight: '50vh',
				padding: '5rem 0',
			}}
		>
			{!category ? (
				<LoadingPageComponent />
			) : (
				<>
					{/* Breadcrumb */}
					<BreadcrumbComponent title={category.title} items={breadcrumbItems} />

					<Container fluid='md'>
						<Row xs={1} sm={2} md={3} className='g-4'>
							{category.courses.map((course, index) => (
								<Flip key={index} delay={index * 50} direction='vertical'>
									<Col>
										<CourseCardComponent
											course={course}
											category={{
												id: category_id,
												title: category.title,
												section_id: section_id,
											}}
										/>
									</Col>
								</Flip>
							))}
						</Row>
					</Container>
				</>
			)}
		</section>
	);
};

export default CategoryDetailsPage;

import axios from 'axios';
import { coursesSlice } from '../reducers/courses.reducer';

const { coursesSuccess, startLoading, hasError } = coursesSlice.actions;

// Actions
export const fetchCourses =
	(
		url = 'https://demoyoursite.net/ibrahim/artvision-backend/public/api/courses',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => dispatch(coursesSuccess(response.data.data)))
			.catch((error) => dispatch(hasError(error.message)));
	};

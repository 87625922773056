import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ar';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { constructURL } from '../helpers/constructURL';

// i18n
import { useTranslation } from 'react-i18next';

// Components
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import CourseCardComponent from '../components/CourseCardComponent';
import LoadingPageComponent from '../components/LoadingPageComponent';
import CategoryCardComponent from './../components/CategoryCardComponent';

const SectionPage = ({ locale }) => {
	locale ? moment.locale('en') : moment.locale('ar');

	// i18n
	const { lang, section_id } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	const [sectionData, setSectionData] = useState(null);

	// Fetch Course Data
	const getSectionData = async (language = 'en') => {
		setSectionData(null);

		axios
			.get(constructURL(`sections/${section_id}`), {
				headers: { locale: language },
			})
			.then((response) => {
				setSectionData(response.data.data);
			});
	};

	// Render Initial Course
	useEffect(() => {
		// Current section data
		getSectionData(locale ? 'en' : 'ar');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	const breadcrumbItems = sectionData && [
		{
			title: t('breadcrumb.home', { ns: 'words' }),
			href: `/${lang}`,
			isActive: false,
		},
		{ title: sectionData.title, href: '', isActive: true },
	];

	return (
		<section
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			id={`section-${section_id}`}
			style={{
				minHeight: '90vh',
				padding: '5rem 0',
			}}
		>
			{!sectionData ? (
				<LoadingPageComponent />
			) : (
				<>
					{/* Breadcrumb */}
					<BreadcrumbComponent
						title={sectionData.title}
						items={breadcrumbItems}
					/>

					<Container fluid='md'>
						{/* Categories Listing */}
						{sectionData &&
							sectionData.categories &&
							sectionData.categories.length > 0 && (
								<Row xs={1} md={2} lg={4} className='g-4'>
									{sectionData.categories.map((category, index) => (
										<Fade key={index} delay={index * 50} direction='up'>
											<Col>
												<Link
													to={
														category.courses_count > 0
															? `/${lang}/section/${section_id}/category/${category.id}`
															: ''
													}
													target='_top'
													style={{
														textDecoration: 'none',
													}}
												>
													<CategoryCardComponent
														category={category}
														locale={locale}
													/>
												</Link>
											</Col>
										</Fade>
									))}
								</Row>
							)}

						{/* Courses Without Category */}
						{sectionData &&
							sectionData.courses &&
							sectionData.courses.length > 0 && (
								<Row xs={1} md={2} lg={4} className='g-4'>
									{sectionData.courses.map((course, index) => (
										<Fade key={index} delay={index * 50} direction='up'>
											<Col>
												<CourseCardComponent course={course} locale={locale} />
											</Col>
										</Fade>
									))}
								</Row>
							)}
					</Container>
				</>
			)}
		</section>
	);
};

export default SectionPage;

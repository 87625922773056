import axios from 'axios';
import { categoriesSlice } from '../reducers/categories.reducer';

const { categoriesSuccess, startLoading, hasError } = categoriesSlice.actions;

// Actions
export const fetchCategories =
	(
		url = 'https://demoyoursite.net/ibrahim/artvision-backend/public/api/categories',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => dispatch(categoriesSuccess(response.data.data)))
			.catch((error) => dispatch(hasError(error.message)));
	};

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Zoom } from 'react-awesome-reveal';
import { Pagination } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { constructURL } from '../helpers/constructURL';

// Style
import '../styles/PaginationComponent.styles.css';

const PaginationComponent = ({ path, searchTerms, handleData }) => {
	const { lang } = useParams();

	// States
	const [pagination, setPagination] = useState(null);

	// Fetch Courses Data
	const getPaginationData = async (pageNumber = 1, language = 'en') => {
		axios({
			method: 'get',
			url: constructURL(path, { ...searchTerms, page: pageNumber }),
			headers: { locale: language },
		}).then((response) => {
			setPagination({
				current: response.data.data.meta.current_page,
				last: response.data.data.meta.last_page,
			});

			switch (path) {
				case 'courses':
					handleData(response.data.data.data);

					break;
				default:
					break;
			}
		});
	};

	// Render Initial Courses
	useEffect(() => {
		getPaginationData(1, lang === 'en' ? 'en' : 'ar');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Handle Pagination Requests
	const handlePagination = (event) => {
		// setIsLoading(true);

		const current =
			event.target.dataset.page ?? event.target.parentNode.dataset.page;

		getPaginationData(current, lang === 'en' ? 'en' : 'ar');

		// Scroll To Top On coursesData update
		document
			.getElementById('courses-filter-result')
			.childNodes[1].scrollIntoView({
				behavior: 'smooth',
				block: 'end',
				inline: 'nearest',
			});
	};

	return (
		pagination && (
			<Zoom delay={50}>
				<Pagination className='d-flex justify-content-center align-items-center mt-5'>
					<Pagination.First
						onClick={handlePagination}
						data-page={1}
						disabled={pagination.current === 1}
					/>
					<Pagination.Prev
						onClick={handlePagination}
						data-page={pagination.current - 1}
						disabled={pagination.current === 1}
					/>

					{/* Pagination Items */}
					{Array.from({ length: pagination.last }).map((_, index) => (
						<Pagination.Item
							key={index + 1}
							data-page={index + 1}
							onClick={handlePagination}
							active={pagination.current === index + 1}
						>
							{index + 1}
						</Pagination.Item>
					))}

					<Pagination.Next
						onClick={handlePagination}
						data-page={pagination.current + 1}
						disabled={pagination.current === pagination.last}
					/>
					<Pagination.Last
						onClick={handlePagination}
						data-page={pagination.last}
						disabled={pagination.current === pagination.last}
					/>
				</Pagination>
			</Zoom>
		)
	);
};

export default PaginationComponent;

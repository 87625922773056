import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const MetaTagsComponent = () => {
	const { lang } = useParams();

	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	// <head></head> Details
	return (
		!isSettingsLoading && (
			<HelmetProvider>
				<Helmet
					htmlAttributes={{
						lang: lang,
						dir: lang === 'en' ? 'ltr' : 'rtl',
					}}
				>
					{/* Open Graph Meta Tags */}
					<meta property='og:title' content={settings.meta.title} />
					<meta property='og:description' content={settings.meta.description} />
					<meta property='og:image' content={settings.images.logo} />

					{/* Meta Tags */}
					<meta name='title' content={settings.meta.title} />
					<meta name='description' content={settings.meta.description} />
					<meta name='keywords' content={settings.meta.keywords} />

					{/* Tab Title */}
					<title>{settings.website_title}</title>

					{/* Favicon */}
					<link rel='icon' type='image/png' href={settings.images.favicon} />
				</Helmet>
			</HelmetProvider>
		)
	);
};

export default MetaTagsComponent;

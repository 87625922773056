import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import '../styles/NotFoundPage.styles.css';

const NotFoundPage = () => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	return (
		<Container
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang}
			id='no-found'
			fluid
			className='text-center d-flex align-items-center text-bg-light'
			style={{
				minHeight: '100vh',
			}}
		>
			<Container fluid='md' className='my-auto'>
				<Row xs={1} lg={2} className='g-4'>
					<Col
						className={`align-middle animate__animated ${
							lang === 'en' ? 'animate__fadeInLeft' : 'animate__fadeInRight'
						}`}
					>
						{/* 404 Image */}
						<Image
							src={require('../assets/images/not-found.png')}
							alt='Not Found Page'
							className='img-fluid'
							style={{
								transform: lang === 'en' ? 'scaleX(-1)' : 'scaleX(1)',
							}}
						/>
					</Col>

					<Col
						className={`d-flex flex-column justify-content-center align-items-center p-5 animate__animated ${
							lang === 'en' ? 'animate__fadeInRight' : 'animate__fadeInLeft'
						}`}
					>
						{/* Main Text */}
						<h1 className='display-1 m-0 p-0'>404</h1>
						<h2 className='display-6 mb-4'>
							{t('errors.404', { ns: 'sentences' })}
						</h2>
						{/* Redirect Button To Home */}
						<Link
							to={`/${lang}`}
							target='_top'
							className='text-decoration-none text-reset'
							style={{
								fontWeight: '600',
								fontSize: '18px',
							}}
						>
							<div className='not-found-btn text-light rounded-2 px-4 py-2'>
								<span
									className='position-relative'
									style={{
										zIndex: '10',
									}}
								>
									{t('buttons.goHome', { ns: 'words' })}
								</span>
							</div>
						</Link>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default NotFoundPage;

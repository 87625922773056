// @ts-nocheck
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import 'moment/locale/ar';
import React, { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
	Accordion,
	Badge,
	CloseButton,
	Col,
	Container,
	FormCheck,
	FormGroup,
	FormLabel,
	Row,
	Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { constructURL } from '../helpers/constructURL';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import '../styles/SearchResultPage.styles.css';

// Components
import ButtonComponent from '../components/ButtonComponent';
import CourseCardComponent from '../components/CourseCardComponent';
import LoadingPageComponent from '../components/LoadingPageComponent';
import NoResultsComponent from '../components/NoResultsComponent';
import PaginationComponent from '../components/PaginationComponent';
import BreadcrumbComponent from './../components/BreadcrumbComponent';

const SearchResultPage = ({ locale }) => {
	locale ? moment.locale('en') : moment.locale('ar');

	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	const titleRef = useRef();

	const { sections, isSectionsLoading } = useSelector(
		(state) => state.sections
	);
	const { categories, isCategoriesLoading } = useSelector(
		(state) => state.categories
	);
	const { isCoursesLoading } = useSelector((state) => state.courses);

	const breadcrumbItems = [
		{
			title: t('breadcrumb.home', { ns: 'words' }),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('breadcrumb.searchResults', { ns: 'words' }),
			href: '',
			isActive: true,
		},
	];

	const [coursesData, setCoursesData] = useState(null);
	const [filterTerms, setFilterTerms] = useState(null);

	const searchTerms = JSON.parse(sessionStorage.getItem('searchTerms')) ??
		filterTerms ?? {
			title: '',
			section_id: '',
			category_id: '',
		};

	const searchSchema = Yup.object().shape({});

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'title':
				// titleRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				// lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				// emailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				// phoneRef.current.classList.add('is-invalid');
				break;

			case 'message':
				// messageRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('errors.default', { ns: 'sentences' }));
				break;
		}
	};

	const addNewValue = (setFieldValue, values, fieldName, value) => {
		setFieldValue(fieldName, [...values[fieldName], value]);

		values[fieldName] = [...values[fieldName], value];
		submitSearchForm(values, undefined, lang === 'en' ? 'en' : 'ar');
	};

	const removeExistingValue = (setFieldValue, values, fieldName, value) => {
		fieldName === 'title'
			? setFieldValue(fieldName, '')
			: setFieldValue(
					fieldName,
					values[fieldName].filter((currentValue) => currentValue !== value)
			  );

		fieldName === 'title'
			? (values[fieldName] = '')
			: (values[fieldName] = values[fieldName].filter(
					(currentValue) => currentValue !== value
			  ));
		submitSearchForm(values, undefined, lang === 'en' ? 'en' : 'ar');
	};

	const formatSearchTerms = (searchTerms) => {
		const validKeys = Object.keys(searchTerms).filter(
			(key) =>
				searchTerms[key] !== '' &&
				searchTerms[key] !== null &&
				searchTerms[key] !== undefined &&
				JSON.stringify(searchTerms[key]) !== JSON.stringify([])
		);

		const data = Object.fromEntries(
			validKeys.map((key) => {
				return Array.isArray(searchTerms[key])
					? [key, JSON.stringify(searchTerms[key])]
					: [key, JSON.stringify([searchTerms[key]])];
			})
		);

		if (data['title']) {
			data['title'] = JSON.parse(data['title'])[0];
		}

		return data;
	};

	const submitSearchForm = async (
		values,
		setSubmitting = undefined,
		language = 'en'
	) => {
		setCoursesData(null);

		const data = formatSearchTerms(values);

		setFilterTerms({
			...data,
		});

		// Remove search terms from session storage
		sessionStorage.removeItem('searchTerms');

		axios({
			method: 'get',
			url: constructURL('courses', {
				...data,
			}),
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				setCoursesData(response.data.data.data);

				// reset submitting
				if (setSubmitting !== undefined) {
					setSubmitting(false);
				}
			})
			.catch((error) => {
				console.log(error);
				// reset submitting
				if (setSubmitting !== undefined) {
					setSubmitting(false);
				}

				if (error.response.status === 401) {
					displayToast(error.response.status, error.response.data);
				} else if (error.response.data.data.length !== 0) {
					Object.keys(error.response.data.data).forEach((key) => {
						displayErrors(key);
						displayToast(
							error.response.status,
							error.response.data.data[key][0]
						);
					});
				} else {
					displayToast(error.response.status, error.response.data.message);
				}
			});
	};

	const handleBadgeRemoval = (values, setFieldValue, fieldName, value) => {
		removeExistingValue(setFieldValue, values, fieldName, value);
	};

	useEffect(() => {
		const getCoursesData = async (language = 'en') => {
			setCoursesData(null);

			axios({
				method: 'get',
				url: constructURL('courses', {
					// ...formatSearchTerms(searchTerms),
				}),
				headers: { locale: language, 'Content-Type': 'multipart/form-data' },
			})
				.then((response) => {
					setCoursesData(response.data.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		};

		if (searchTerms) {
			getCoursesData(lang === 'en' ? 'en' : 'ar');
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	return (
		<section
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			id='search-result'
			style={{
				minHeight: '90vh',
				padding: '5rem 0',
				overflow: 'visible',
			}}
		>
			{isSectionsLoading || isCategoriesLoading || isCoursesLoading ? (
				<LoadingPageComponent />
			) : (
				<>
					{/* Toast Messages */}
					<ToastContainer
						position='top-right'
						autoClose={10000}
						newestOnTop
						pauseOnHover
						rtl={locale ? false : true}
					/>

					{/* Breadcrumb */}
					<BreadcrumbComponent
						title={t('breadcrumb.searchResults', { ns: 'words' })}
						items={breadcrumbItems}
					/>

					<Container fluid='md'>
						<Row xs={1} lg={2} className='g-4'>
							{/* Search Form */}
							<Col lg={3} className='position-relative'>
								<Formik
									initialValues={{
										title: searchTerms ? searchTerms.title : '',
										section_id: searchTerms ? searchTerms.section_id : '',
										category_id: searchTerms ? searchTerms.category_id : '',
									}}
									validationSchema={searchSchema}
									onSubmit={(values, { setSubmitting, resetForm }) => {
										setSubmitting(true);
										submitSearchForm(
											values,
											setSubmitting,
											resetForm,
											lang === 'en' ? 'en' : 'ar'
										);
									}}
								>
									{({
										values,
										errors,
										touched,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										setFieldValue,
									}) => (
										<Row
											xs={1}
											style={{
												minHeight: '20rem',
												position: 'sticky',
												top: '8rem',
											}}
										>
											{/* Search Badges */}
											{(values.title ||
												values.section_id ||
												values.category_id) && (
												<Col className='mb-2'>
													{/* Title Badge */}
													{values.title !== '' && (
														<Badge className='text-bg-danger'>
															{values.title}
															<CloseButton
																className={`${locale ? 'ms-2' : 'me-2'}`}
																onClick={() =>
																	handleBadgeRemoval(
																		values,
																		setFieldValue,
																		'title',
																		''
																	)
																}
															/>
														</Badge>
													)}

													{/* Section Badge */}
													{!isSectionsLoading &&
														values.section_id &&
														values.section_id.length > 0 &&
														values.section_id.map((currentSection, index) => (
															<Badge key={index} id={currentSection}>
																{
																	sections.filter(
																		(section) =>
																			currentSection === `${section.id}`
																	)[0].title
																}

																<CloseButton
																	className={`${locale ? 'ms-2' : 'me-2'}`}
																	onClick={(event) =>
																		handleBadgeRemoval(
																			values,
																			setFieldValue,
																			'section_id',
																			event.target.parentElement.id
																		)
																	}
																/>
															</Badge>
														))}

													{/* Category Badge */}
													{!isCategoriesLoading &&
														values.category_id &&
														values.category_id.length > 0 &&
														values.category_id.map((currentCategory, index) => (
															<Badge key={index} id={currentCategory}>
																{
																	categories.filter(
																		(category) =>
																			currentCategory === `${category.id}`
																	)[0].title
																}

																<CloseButton
																	className={`${locale ? 'ms-2' : 'me-2'}`}
																	onClick={(event) =>
																		handleBadgeRemoval(
																			values,
																			setFieldValue,
																			'category_id',
																			event.target.parentElement.id
																		)
																	}
																/>
															</Badge>
														))}
												</Col>
											)}

											{/* Search Form */}
											<Col>
												<Form
													onSubmit={(event) => {
														event.preventDefault();
														handleSubmit();
													}}
													className='p-3 overflow-hidden'
													style={{
														backgroundColor: '#f9fafd',
														border: '1px solid #e5f1fb',
														borderRadius: '0.375rem',
													}}
												>
													<Row xs={1}>
														{/* Title Filter */}
														<FormGroup as={Col} className='mb-3'>
															<FormLabel
																htmlFor='course_title'
																className='text-capitalize'
															>
																{t('labels.courseKeywords', { ns: 'words' })}
															</FormLabel>
															<Field
																id='course_title'
																type='text'
																innerRef={titleRef}
																placeholder={t('placeholders.courseKeywords', {
																	ns: 'words',
																})}
																autoComplete='off'
																name='title'
																onChange={handleChange}
																onBlur={(event) => {
																	handleBlur(event);
																	submitSearchForm(
																		values,
																		undefined,
																		lang === 'en' ? 'en' : 'ar'
																	);
																}}
																value={values.title}
																onKeyDown={(event) => {
																	if (event.keyCode === 13) {
																		submitSearchForm(
																			values,
																			undefined,
																			lang === 'en' ? 'en' : 'ar'
																		);
																	}
																}}
																className={`form-control text-capitalize ${
																	touched.title && errors.title
																		? 'is-invalid'
																		: ''
																}`}
															/>
															<ErrorMessage
																component='div'
																name='title'
																className='invalid-feedback'
															/>
														</FormGroup>
													</Row>

													<Row xs={1} sm={2}>
														<Accordion className='w-100'>
															{/* Sections Filter */}
															<Accordion.Item eventKey='0'>
																<Accordion.Header>
																	{t('labels.section', { ns: 'words' })}
																	{values.section_id &&
																		values.section_id.length > 0 && (
																			<>
																				<Badge className='accordion-badge'>
																					{values.section_id.length}
																				</Badge>
																				<span className='visually-hidden'>
																					{t('selectedFilters', {
																						ns: 'words',
																					})}
																				</span>
																			</>
																		)}
																</Accordion.Header>
																<Accordion.Body>
																	{!isSectionsLoading &&
																		sections.map((section) => (
																			<FormCheck
																				key={section.title}
																				inline
																				reverse={locale ? false : true}
																				type='checkbox'
																				id={section.title}
																				name='section_id'
																				value={section.id}
																				checked={
																					values.section_id &&
																					values.section_id.includes(
																						`${section.id}`
																					)
																				}
																				onChange={(event) => {
																					event.target.checked
																						? addNewValue(
																								setFieldValue,
																								values,
																								'section_id',
																								event.target.value
																						  )
																						: removeExistingValue(
																								setFieldValue,
																								values,
																								'section_id',
																								event.target.value
																						  );
																				}}
																				label={section.title}
																				className='filter-option'
																			/>
																		))}
																</Accordion.Body>
															</Accordion.Item>

															{/* Categories Filter */}
															<Accordion.Item eventKey='1'>
																<Accordion.Header>
																	{t('labels.category', { ns: 'words' })}
																	{values.category_id &&
																		values.category_id.length > 0 && (
																			<>
																				<Badge className='accordion-badge'>
																					{values.category_id.length}
																				</Badge>
																				<span className='visually-hidden'>
																					{t('selectedFilters', {
																						ns: 'words',
																					})}
																				</span>
																			</>
																		)}
																</Accordion.Header>
																<Accordion.Body>
																	{!isCategoriesLoading &&
																		categories.map((category) => (
																			<FormCheck
																				key={category.title}
																				inline
																				reverse={locale ? false : true}
																				type='checkbox'
																				id={category.title}
																				name='category_id'
																				value={category.id}
																				checked={
																					values.category_id &&
																					values.category_id.includes(
																						`${category.id}`
																					)
																				}
																				onChange={(event) => {
																					event.target.checked
																						? addNewValue(
																								setFieldValue,
																								values,
																								'category_id',
																								event.target.value
																						  )
																						: removeExistingValue(
																								setFieldValue,
																								values,
																								'category_id',
																								event.target.value
																						  );
																				}}
																				label={category.title}
																				className='filter-option'
																			/>
																		))}
																</Accordion.Body>
															</Accordion.Item>
														</Accordion>
													</Row>

													{/* Submit Form */}
													<FormGroup
														className='d-flex justify-content-center mt-3 animate__animated animate__zoomIn animate__delay-1s'
														style={{
															'--animate-delay': '1.25s',
														}}
													>
														<ButtonComponent
															title={
																isSubmitting
																	? t('buttons.searching', {
																			ns: 'words',
																	  })
																	: t('buttons.applyFilter', {
																			ns: 'words',
																	  })
															}
															icon={
																isSubmitting ? (
																	<Spinner
																		animation='grow'
																		variant='light'
																		size='sm'
																		className={`${locale ? 'me-2' : 'ms-2'}`}
																	/>
																) : (
																	<></>
																)
															}
															type='submit'
															disabled={isSubmitting ? true : false}
														/>
													</FormGroup>
												</Form>
											</Col>
										</Row>
									)}
								</Formik>
							</Col>

							{/* Search Results */}
							<Col lg={9}>
								{!coursesData ? (
									// Loading Indicator
									<LoadingPageComponent />
								) : coursesData.length > 0 ? (
									// Display Courses Data
									<>
										<Row
											xs={1}
											md={2}
											xxl={3}
											id='courses-filter-result'
											className='g-4 overflow-hidden'
										>
											{coursesData.map((course, index) => (
												<Fade key={index} delay={50} direction='up'>
													<Col>
														<CourseCardComponent
															course={course}
															locale={locale}
														/>
													</Col>
												</Fade>
											))}
										</Row>

										<PaginationComponent
											path='courses'
											searchTerms={searchTerms ?? filterTerms}
											handleData={setCoursesData}
										/>
									</>
								) : (
									// No Results Found
									<NoResultsComponent />
								)}
							</Col>
						</Row>
					</Container>
				</>
			)}
		</section>
	);
};

export default SearchResultPage;

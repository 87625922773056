const BASE_URL = {
	local: 'http://localhost:8000/api',
	demo: 'https://demoyoursite.xyz/ibrahim/artvision-backend/public/api',
};

/**
 * @description Construct url using desired parameters
 * @param {String} infoType
 * @param {Object} searchParams
 * @returns {URL} Constructed url
 */
export const constructURL = (infoType, searchParams = {}) => {
	const currentEnvURL =
		window.location.hostname === 'localhost' ? BASE_URL.local : BASE_URL.demo;
	const url = new URL(`${currentEnvURL}/${infoType}`);
	url.search = new URLSearchParams({ ...searchParams });

	return url;
};

import React from 'react';
import { Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const InstructorAvatarComponent = ({
	image,
	name,
	styles = {
		text: { fontSize: '16px' },
		image: { width: '30px', height: '30px' },
	},
}) => {
	const { lang } = useParams();

	return (
		<div className='d-flex justify-content-center align-align-items-center'>
			<Image
				fluid
				src={image}
				alt='Instructor Thumbnail'
				className={`rounded-circle ${lang === 'en' ? 'me-2' : 'ms-2'}`}
				style={{
					border: '1px solid #808191',
					objectFit: 'cover',
					objectPosition: 'top',
					...styles.image,
				}}
			/>

			<span
				className='text-limit'
				style={{
					fontWeight: '500',
					color: '#808191',
					'--lines': 1,
					...styles.text,
				}}
			>
				{name}
			</span>
		</div>
	);
};

export default InstructorAvatarComponent;

import { createSlice } from '@reduxjs/toolkit';

export const footerDataSlice = createSlice({
	name: 'footerData',
	initialState: {
		footerData: {},
		companyLogo: '',
		whatsappNumber: '',
		isFooterDataLoading: true,
	},
	reducers: {
		footerDataSuccess: (state, action) => {
			state.footerData = action.payload;
			state.companyLogo = action.payload.logo;
			state.whatsappNumber = action.payload.whatsapp;
			state.isFooterDataLoading = false;
		},

		startLoading: (state) => {
			state.isFooterDataLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isFooterDataLoading = false;
		},
	},
});

export default footerDataSlice.reducer;

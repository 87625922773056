import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
	Col,
	Container,
	FormControl,
	FormGroup,
	FormLabel,
	Image,
	OverlayTrigger,
	Popover,
	Row,
	Spinner,
} from 'react-bootstrap';
import { FaCamera, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { constructURL } from '../helpers/constructURL';
import useUserData from '../hooks/useUserData';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import '../styles/SignUpPage.styles.css';

// Components
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import LoadingPageComponent from '../components/LoadingPageComponent';
import ButtonComponent from './../components/ButtonComponent';

const SignUpPage = ({ locale }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	const { setUserData } = useUserData();
	const navigate = useNavigate();

	const imageRef = useRef();
	const imagePreviewRef = useRef();
	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const emailRef = useRef();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();

	const FILE_SIZE = 900 * 1000; // 900 KB
	const SUPPORTED_FORMATS = ['image/jpg', 'image/png', 'image/jpeg'];

	const breadcrumbItems = [
		{
			title: t('breadcrumb.home', { ns: 'words' }),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('breadcrumb.signup', { ns: 'words' }),
			href: '',
			isActive: true,
		},
	];

	const signupSchema = Yup.object().shape({
		fname: Yup.string()
			.min(2, t('firstName.min', { ns: 'validations', min: 2 }))
			.max(100, t('firstName.max', { ns: 'validations', max: 100 }))
			.required(t('firstName.required', { ns: 'validations' })),

		lname: Yup.string()
			.min(2, t('lastName.min', { ns: 'validations', min: 2 }))
			.max(100, t('lastName.max', { ns: 'validations', max: 100 }))
			.required(t('lastName.required', { ns: 'validations' })),

		email: Yup.string()
			.email(t('email.format', { ns: 'validations' }))
			.required(t('email.required', { ns: 'validations' })),

		password: Yup.string()
			.min(6, t('password.min', { ns: 'validations', min: 6 }))
			.required(t('password.required', { ns: 'validations' })),

		confirmPassword: Yup.string()
			.oneOf(
				[Yup.ref('password')],
				t('passwordConfirmation.match', { ns: 'validations' })
			)
			.required(t('passwordConfirmation.required', { ns: 'validations' })),

		image: Yup.mixed()
			.nullable()
			.notRequired()
			.test(
				'fileSize',
				t('image.size', { ns: 'validations' }),
				(value) => !value || (value && value.size <= FILE_SIZE)
			) // MAX_SIZE is (900KB)
			.test(
				'fileFormat',
				t('image.format', { ns: 'validations' }),
				(value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
			),
	});

	const [isLoading, setIsLoading] = useState(true);
	const [isPasswordShown, setIsPasswordShown] = useState(false);
	const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'password':
				if (passwordRef.current.classList.contains('is-valid')) {
					passwordRef.current.classList.remove('is-valid');
					confirmPasswordRef.current.classList.remove('is-valid');
				}

				passwordRef.current.classList.add('is-invalid');
				break;

			case 'password_confirmation':
				if (confirmPasswordRef.current.classList.contains('is-valid')) {
					confirmPasswordRef.current.classList.remove('is-valid');
					passwordRef.current.classList.remove('is-valid');
				}

				confirmPasswordRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('errors.default', { ns: 'sentences' }));
				break;
		}
	};

	const submitSignupForm = async (values, setSubmitting, language = 'en') => {
		// api: /register
		axios({
			method: 'post',
			url: constructURL('register'),
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				password: values.password,
				password_confirmation: values.confirmPassword,
				image: values.image,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				setUserData({ ...response.data.data, enrolled_courses: [] });
				navigate(sessionStorage.getItem('beforeSignup') ?? `/${lang}`);
				window.location.reload();
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				typeof error.response.data.data === typeof {}
					? Object.keys(error.response.data.data).forEach((key) => {
							displayErrors(key);
							displayToast(
								error.response.status,
								error.response.data.data[key][0]
							);
					  })
					: displayToast(error.response.status, error.response.data.data);
			});
	};

	useEffect(() => {
		setIsLoading(true);
		setTimeout(() => setIsLoading(false), 500);
	}, [locale]);

	return (
		<section
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang === 'en' ? 'en' : 'ar'}
			id='signup-form'
			style={{
				minHeight: '100vh',
				padding: '5rem 0',
			}}
		>
			{isLoading ? (
				<LoadingPageComponent />
			) : (
				<>
					{/* Toast Messages */}
					<ToastContainer
						position='top-right'
						autoClose={10000}
						newestOnTop
						pauseOnHover
						rtl={locale ? false : true}
					/>

					{/* Breadcrumb */}
					<BreadcrumbComponent
						title={t('breadcrumb.signup', { ns: 'words' })}
						items={breadcrumbItems}
					/>

					<Container
						fluid='sm'
						style={{
							maxWidth: '600px',
						}}
					>
						<Row
							xs={1}
							className='g-4'
							style={{
								paddingTop: '4rem',
							}}
						>
							{/* Page Form Container */}
							<Col
								className='p-4 animate__animated animate__fadeInTop animate__delay-1s'
								style={{
									backgroundColor: '#f9fafd',
									border: '1px solid #e5f1fb',
									borderRadius: '0.375rem',
									'--animate-delay': '0.5s',
								}}
							>
								<Row xs={1} className='g-4'>
									<Col>
										<Formik
											initialValues={{
												fname: '',
												lname: '',
												email: '',
												password: '',
												confirmPassword: '',
												image: null,
											}}
											validationSchema={signupSchema}
											onSubmit={(values, { setSubmitting, resetForm }) => {
												setSubmitting(true);
												submitSignupForm(
													values,
													setSubmitting,
													lang === 'en' ? 'en' : 'ar'
												);
											}}
										>
											{({
												values,
												errors,
												touched,
												handleChange,
												handleBlur,
												handleSubmit,
												isSubmitting,
												setFieldValue,
											}) => (
												<Form
													className='position-relative'
													onSubmit={(event) => {
														event.preventDefault();
														handleSubmit();
													}}
													style={{
														paddingTop: '4rem',
													}}
												>
													{/* Avatar Selection */}
													<FormGroup
														className={`avatar-image ${
															errors.image ? 'is-invalid' : ''
														} position-absolute rounded-circle`}
														style={{
															top: '-20px',
															left: '50%',
															transform: 'translate(-50%, -50%)',
															zIndex: '99',
														}}
													>
														{/* Avatar Preview */}
														{!errors.image && values.image && (
															<OverlayTrigger
																trigger={['hover', 'focus']}
																placement='top'
																overlay={
																	<Popover
																		dir={locale ? 'ltr' : 'rtl'}
																		lang={locale ? 'en' : 'ar'}
																	>
																		<Popover.Body>
																			{t('editAvatarImage', { ns: 'words' })}
																		</Popover.Body>
																	</Popover>
																}
															>
																<Image
																	fluid
																	src={URL.createObjectURL(values.image)}
																	alt='Avatar Thumbnail'
																	className={`rounded-circle ${
																		touched.image && errors.image
																			? 'is-invalid'
																			: ''
																	}`}
																	style={{
																		objectFit: 'cover',
																		objectPosition: 'top',
																		height: '8rem',
																		width: '8rem',
																		cursor: 'pointer',
																	}}
																	onClick={() => imageRef.current.click()}
																/>
															</OverlayTrigger>
														)}

														{/* Remove Avatar Icon */}
														{!errors.image && values.image && (
															<MdClose
																className='position-absolute rounded-circle p-1'
																style={{
																	top: '0',
																	right: locale ? '0' : 'unset',
																	left: locale ? 'unset' : '0',
																	backgroundColor: '#f9fafd',
																	border: '1px solid #e5f1fb',
																	cursor: 'pointer',
																}}
																size={30}
																color='#b94a4b'
																onClick={() => setFieldValue('image', null)}
															/>
														)}

														{/* Add Avatar Icon */}
														{(!values.image || errors.image) && (
															<OverlayTrigger
																trigger={['hover', 'focus']}
																placement='top'
																overlay={
																	<Popover
																		dir={locale ? 'ltr' : 'rtl'}
																		lang={locale ? 'en' : 'ar'}
																	>
																		<Popover.Body>
																			{t('addAvatarImage', { ns: 'words' })}
																		</Popover.Body>
																	</Popover>
																}
															>
																<div
																	ref={imagePreviewRef}
																	className='avatar-icon p-1 d-flex justify-content-center align-items-center rounded-circle border'
																	style={{
																		width: '8rem',
																		height: '8rem',
																		backgroundColor: 'rgb(147, 202, 255)',
																		cursor: 'pointer',
																	}}
																	onClick={() => imageRef.current.click()}
																>
																	<FaCamera size={40} />
																</div>
															</OverlayTrigger>
														)}

														{/* Avatar Input File */}
														<Field name='image' innerRef={imageRef}>
															{(field, form, meta) => (
																<>
																	<FormControl
																		{...field}
																		type='file'
																		accept='image/jpg,image/png,image/jpeg'
																		ref={imageRef}
																		hidden
																		onChange={(event) => {
																			setFieldValue(
																				'image',
																				event.currentTarget.files[0]
																			);

																			// Remove (invalid input) styles
																			field.meta.error
																				? (imagePreviewRef.current.style.borderColor =
																						'#dc3545')
																				: (imagePreviewRef.current.style.borderColor =
																						'');
																		}}
																		onBlur={handleBlur}
																		className={`form-control ${
																			field.meta.error ? 'is-invalid' : ''
																		}`}
																	/>

																	{field.meta.error && (
																		<div className='invalid-feedback'>
																			{field.meta.error}
																		</div>
																	)}
																</>
															)}
														</Field>
													</FormGroup>

													<Row xs={1} sm={2}>
														{/* First Name */}
														<FormGroup
															as={Col}
															className={`mb-3 animate__animated ${
																locale
																	? 'animate__fadeInRight'
																	: 'animate__fadeInLeft'
															} animate__delay-1s`}
															style={{
																'--animate-delay': '0.5s',
															}}
														>
															<FormLabel
																htmlFor='first_name'
																className='text-capitalize'
															>
																{t('labels.firstName', { ns: 'words' })}
															</FormLabel>
															<Field
																id='first_name'
																type='text'
																innerRef={firstNameRef}
																placeholder={t('placeholders.firstName', {
																	ns: 'words',
																})}
																autoComplete='off'
																name='fname'
																onChange={(event) => {
																	handleChange(event);
																}}
																onBlur={handleBlur}
																value={values.fname}
																className={`form-control text-capitalize ${
																	touched.fname && errors.fname
																		? 'is-invalid'
																		: ''
																}`}
															/>
															<ErrorMessage
																component='div'
																name='fname'
																className='invalid-feedback'
															/>
														</FormGroup>

														{/* Last Name */}
														<FormGroup
															as={Col}
															className={`mb-3 animate__animated ${
																locale
																	? 'animate__fadeInRight'
																	: 'animate__fadeInLeft'
															} animate__delay-1s`}
															style={{
																'--animate-delay': '0.5s',
															}}
														>
															<FormLabel
																htmlFor='last_name'
																className='text-capitalize'
															>
																{t('labels.lastName', { ns: 'words' })}
															</FormLabel>
															<Field
																id='last_name'
																type='text'
																innerRef={lastNameRef}
																placeholder={t('placeholders.lastName', {
																	ns: 'words',
																})}
																autoComplete='off'
																name='lname'
																onChange={(event) => {
																	handleChange(event);
																}}
																onBlur={handleBlur}
																value={values.lname}
																className={`form-control text-capitalize ${
																	touched.lname && errors.lname
																		? 'is-invalid'
																		: ''
																}`}
															/>
															<ErrorMessage
																component='div'
																name='lname'
																className='invalid-feedback'
															/>
														</FormGroup>
													</Row>

													{/* Email */}
													<FormGroup
														as={Col}
														className={`mb-3 animate__animated ${
															locale
																? 'animate__fadeInLeft'
																: 'animate__fadeInRight'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '0.75s',
														}}
													>
														<FormLabel
															htmlFor='email'
															className='text-capitalize'
														>
															{t('labels.email', { ns: 'words' })}
														</FormLabel>
														<Field
															id='email'
															type='email'
															innerRef={emailRef}
															placeholder='mail@domain.com'
															autoComplete='off'
															name='email'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.email}
															className={`form-control ${
																touched.email && errors.email
																	? 'is-invalid'
																	: ''
															}`}
														/>
														<ErrorMessage
															component='div'
															name='email'
															className='invalid-feedback'
														/>
													</FormGroup>

													{/* Password */}
													<FormGroup
														as={Col}
														className={`position-relative mb-3 animate__animated ${
															locale
																? 'animate__fadeInRight'
																: 'animate__fadeInLeft'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '1s',
														}}
													>
														<FormLabel
															htmlFor='password'
															className='text-capitalize'
														>
															{t('labels.password', { ns: 'words' })}
														</FormLabel>
														<Field
															id='password'
															type={isPasswordShown ? 'text' : 'password'}
															innerRef={passwordRef}
															placeholder={t('placeholders.password', {
																ns: 'words',
															})}
															autoComplete='off'
															name='password'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.password}
															className={`form-control ${
																touched.password && errors.password
																	? 'is-invalid'
																	: values.password !== '' &&
																	  values.password === values.confirmPassword
																	? 'is-valid'
																	: ''
															}`}
														/>
														<div
															className='show-password text-muted position-absolute'
															onClick={() =>
																setIsPasswordShown(!isPasswordShown)
															}
														>
															{isPasswordShown ? (
																<FaRegEye size={26} />
															) : (
																<FaRegEyeSlash size={26} />
															)}
														</div>
														<ErrorMessage
															component='div'
															name='password'
															className='invalid-feedback'
														/>
													</FormGroup>

													{/* Confirm Password */}
													<FormGroup
														as={Col}
														className={`position-relative mb-3 animate__animated ${
															locale
																? 'animate__fadeInLeft'
																: 'animate__fadeInRight'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '1.25s',
														}}
													>
														<FormLabel
															htmlFor='confirm-password'
															className='text-capitalize'
														>
															{t('labels.passwordConfirmation', {
																ns: 'words',
															})}
														</FormLabel>
														<Field
															id='confirm-password'
															type={
																isConfirmPasswordShown ? 'text' : 'password'
															}
															placeholder={t(
																'placeholders.passwordConfirmation',
																{ ns: 'words' }
															)}
															innerRef={confirmPasswordRef}
															autoComplete='off'
															name='confirmPassword'
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.confirmPassword}
															className={`form-control ${
																touched.confirmPassword &&
																errors.confirmPassword
																	? 'is-invalid'
																	: values.password !== '' &&
																	  values.password === values.confirmPassword
																	? 'is-valid'
																	: ''
															}`}
														/>
														<div
															className='show-password text-muted position-absolute'
															onClick={() =>
																setIsConfirmPasswordShown(
																	!isConfirmPasswordShown
																)
															}
														>
															{isConfirmPasswordShown ? (
																<FaRegEye size={26} />
															) : (
																<FaRegEyeSlash size={26} />
															)}
														</div>
														<ErrorMessage
															component='div'
															name='confirmPassword'
															className='invalid-feedback'
														/>
													</FormGroup>

													{/* Submit Form */}
													<FormGroup
														className='d-flex justify-content-center mt-3 animate__animated animate__zoomIn animate__delay-1s mt-5 mb-3'
														style={{
															'--animate-delay': '1.75s',
														}}
													>
														<ButtonComponent
															title={
																isSubmitting
																	? t('buttons.loading', { ns: 'words' })
																	: t('buttons.signup', { ns: 'words' })
															}
															icon={
																isSubmitting ? (
																	<Spinner
																		animation='grow'
																		variant='light'
																		size='sm'
																		className={`${locale ? 'me-2' : 'ms-2'}`}
																	/>
																) : (
																	<></>
																)
															}
															type='submit'
															styles={{
																button: {
																	width: '100%',
																	'--bs-btn-padding-x': '30px',
																	'--bs-btn-padding-y': '20px',
																},
															}}
															disabled={isSubmitting ? true : false}
														/>
													</FormGroup>

													{/* Signin Link */}
													<FormGroup
														className='d-flex justify-content-center align-items-center mt-4 animate__animated animate__zoomIn animate__delay-1s'
														style={{
															'--animate-delay': '2s',
														}}
													>
														<span
															className={`text-capitalize ${
																locale ? 'me-2' : 'ms-2'
															}`}
															style={{
																color: '#808191',
															}}
														>
															{t('alreadyHaveAccount', { ns: 'sentences' })}
														</span>
														<Link
															to={`/${lang}/login`}
															target='_top'
															className='other-option fw-bold'
															style={{
																textDecoration: 'none',
																textTransform: 'capitalize',
															}}
														>
															{t('login', { ns: 'words' })}
														</Link>
													</FormGroup>
												</Form>
											)}
										</Formik>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</section>
	);
};

export default SignUpPage;

import 'country-flag-icons/3x2/flags.css';
import { GB, SA } from 'country-flag-icons/react/3x2';
import React, { useEffect, useState } from 'react';
import {
	Container,
	Image,
	Nav,
	NavDropdown,
	Navbar,
	Offcanvas,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { ImUser } from 'react-icons/im';
import { IoIosArrowDown } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import { fetchCategories } from '../actions/categories.action';
import { fetchCourses } from '../actions/courses.action';
import { fetchHomeData } from '../actions/homeData.action';
import { fetchMonths } from '../actions/months.action';
import { fetchSections } from '../actions/sections.action';
import { fetchSettingsData } from '../actions/settingsData.action';
import { constructURL } from '../helpers/constructURL';
import useUserData from '../hooks/useUserData';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import '../styles/NavbarComponent.styles.css';

// Components
import DefaultLogo from '../assets/images/logos/logo.png';
import SearchModalComponent from './SearchModalComponent';
import UserAvatarComponent from './UserAvatarComponent';

const NavbarComponent = ({ locale, handleLocale, language }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	const location = useLocation();

	const { userData } = useUserData();

	const dispatch = useDispatch();

	const { isHomeDataLoading } = useSelector((state) => state.homeData);
	const { sections, isSectionsLoading } = useSelector(
		(state) => state.sections
	);

	const { settings, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	// States
	const [modalShow, setModalShow] = useState(false);

	useEffect(() => {
		dispatch(fetchHomeData(constructURL('home'), locale ? 'en' : 'ar'));
		dispatch(fetchSections(constructURL('sections'), locale ? 'en' : 'ar'));
		dispatch(fetchCategories(constructURL('categories'), locale ? 'en' : 'ar'));
		dispatch(fetchCourses(constructURL('courses'), locale ? 'en' : 'ar'));
		dispatch(fetchMonths(locale ? 'en' : 'ar'));
		dispatch(fetchSettingsData(constructURL('settings'), locale ? 'en' : 'ar'));
	}, [dispatch, locale]);

	return !isHomeDataLoading && !isSectionsLoading ? (
		<Navbar
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			bg='white'
			expand='lg'
			fixed='top'
			className='border d-flex justify-content-center align-items-center flex-column'
			style={{
				boxShadow: '0 0 4px #0f0e0e',
			}}
		>
			<Container
				fluid='md'
				className='py-2'
				id='navbar-content'
				style={{
					paddingRight: '1.2rem',
					paddingLeft: '1.2rem',
				}}
			>
				{/* Company Logo */}
				<Navbar.Brand as={Link} to={`/${language}`}>
					<Image
						src={isSettingsLoading ? DefaultLogo : settings.images.logo}
						className='mr-0'
						style={{
							maxHeight: '5rem',
						}}
						alt='Company Logo'
					/>
				</Navbar.Brand>

				{/* Search Courses Icon */}
				<Navbar.Text
					id='search-icon'
					role='button'
					className={`d-flex mx-2 ${locale ? 'ms-auto' : 'me-auto'}`}
					style={{
						order: window.innerWidth < 992 ? 0 : 1,
					}}
					onClick={() => setModalShow(true)}
				>
					{/* Search Icon */}
					<FaSearch size={30} />
				</Navbar.Text>

				{/* Search Modal */}
				<SearchModalComponent
					locale={locale}
					show={modalShow}
					onHide={() => {
						setModalShow(false);
					}}
				/>

				<Navbar.Toggle aria-controls='offcanvasNavbar-expand-lg' />

				<Navbar.Offcanvas
					dir={locale ? 'ltr' : 'rtl'}
					lang={locale ? 'en' : 'ar'}
					id='offcanvasNavbar-expand-lg'
					aria-labelledby='offcanvasNavbarLabel-expand-lg'
					placement={locale ? 'end' : 'start'}
				>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title id='offcanvasNavbarLabel-expand-expand'>
							{/* Company Logo */}
							<Navbar.Brand as={Link} to={`/${language}`}>
								<Image
									src={isSettingsLoading ? DefaultLogo : settings.images.logo}
									className='mr-0'
									style={{
										maxHeight: '5rem',
									}}
									alt='Company Logo'
								/>
							</Navbar.Brand>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Nav
							className={`text-capitalize ${locale ? 'ms-auto' : 'me-auto'}`}
						>
							{/* Home */}
							<NavLink
								end
								to={`/${language}`}
								className={({ isActive }) =>
									isActive ? 'nav-link active' : 'nav-link'
								}
							>
								{t('navbar.home', { ns: 'words' })}
							</NavLink>

							{/* Training Courses */}
							<NavDropdown
								title={
									<>
										<span>{t('navbar.trainingCourses', { ns: 'words' })}</span>
										<IoIosArrowDown />
									</>
								}
								id='training-courses-nav-dropdown'
							>
								{!isSectionsLoading && sections && (
									<>
										{sections.map((section, index) => (
											<NavDropdown.Item
												key={index}
												href={`/${language}/section/${section.id}`}
												className='text-capitalize'
											>
												{section.title}
											</NavDropdown.Item>
										))}
									</>
								)}
							</NavDropdown>

							{/* About Us */}
							<NavLink
								to={`/${language}/about-us`}
								className={({ isActive }) =>
									isActive ? 'nav-link active' : 'nav-link'
								}
							>
								{t('navbar.aboutUs', { ns: 'words' })}
							</NavLink>

							{/* Contact Us */}
							<NavLink
								to={`/${language}/contact-us`}
								className={({ isActive }) =>
									isActive ? 'nav-link active' : 'nav-link'
								}
							>
								{t('navbar.contactUs', { ns: 'words' })}
							</NavLink>

							{/* User Actions */}
							{userData ? (
								<UserAvatarComponent locale={locale} />
							) : (
								<>
									<NavDropdown
										title={
											<>
												<OverlayTrigger
													className='text-capitalize'
													placement='bottom'
													overlay={
														<Tooltip>
															{t('navbar.account', { ns: 'words' })}
														</Tooltip>
													}
												>
													<div>
														<ImUser size={25} className='position-relative' />
													</div>
												</OverlayTrigger>
											</>
										}
										id='account-nav-dropdown'
									>
										{/* Log In */}
										<NavDropdown.Item className='text-capitalize'>
											<NavLink
												to={`/${language}/login`}
												className={`login-btn nav-link  ${({ isActive }) =>
													isActive ? 'active' : ''}`}
												onClick={() =>
													sessionStorage.setItem(
														'beforeLogin',
														location.pathname
													)
												}
											>
												{t('navbar.login', { ns: 'words' })}
											</NavLink>
										</NavDropdown.Item>

										{/* Sign Up */}
										<NavDropdown.Item className='text-capitalize'>
											<NavLink
												to={`/${language}/signup`}
												className={`signup-btn nav-link ${({ isActive }) =>
													isActive ? 'active' : ''}`}
												onClick={() =>
													sessionStorage.setItem(
														'beforeSignup',
														location.pathname
													)
												}
											>
												{t('navbar.signup', { ns: 'words' })}
											</NavLink>
										</NavDropdown.Item>
									</NavDropdown>
								</>
							)}

							{/* Translate */}
							<Navbar.Text
								id='translation'
								role='button'
								className='px-lg-2 d-flex justify-content-center align-items-center text-uppercase'
								onClick={handleLocale}
								style={{
									fontFamily: 'Marhey',
									fontWeight: '500',
								}}
							>
								<span className='mx-2'>
									{locale ? <SA width='1.5rem' /> : <GB width='1.5rem' />}
								</span>
								{t('navbar.otherLanguage', { ns: 'words' })}
							</Navbar.Text>
						</Nav>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	) : (
		<></>
	);
};

export default NavbarComponent;

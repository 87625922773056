import { createSlice } from '@reduxjs/toolkit';

// Slice

export const categoriesSlice = createSlice({
	name: 'categories',
	initialState: {
		categories: [],
		isCategoriesLoading: true,
	},
	reducers: {
		categoriesSuccess: (state, action) => {
			state.categories = action.payload;
			state.isCategoriesLoading = false;
		},

		startLoading: (state) => {
			state.isCategoriesLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isCategoriesLoading = false;
		},
	},
});

export default categoriesSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

// Slice

export const sectionsSlice = createSlice({
	name: 'sections',
	initialState: {
		sections: [],
		isSectionsLoading: true,
	},
	reducers: {
		sectionsSuccess: (state, action) => {
			state.sections = action.payload;
			state.isSectionsLoading = false;
		},

		startLoading: (state) => {
			state.isSectionsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isSectionsLoading = false;
		},
	},
});

export default sectionsSlice.reducer;

import React from 'react';
import { Card } from 'react-bootstrap';
import { trimEmptyTags } from '../helpers/trimEmptyTags';

// Styles
import '../styles/FeatureCardComponent.styles.css';

const FeatureCardComponent = ({ feature, locale, ...props }) => {
	return (
		<Card
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			className='feature-card align-items-center px-1 py-4'
			style={{
				border: 'none',
				height: '17rem',
			}}
			{...props}
		>
			<Card.Img
				variant='top'
				src={feature.image}
				alt='Feature Thumbnail'
				style={{
					maxWidth: '5rem',
				}}
			/>
			<Card.Body className='text-center'>
				<Card.Title
					className='text-capitalize text-limit'
					style={{
						fontSize: '32px',
						fontWeight: '500',
						margin: '20px 0',
						'--lines': 1,
					}}
					dangerouslySetInnerHTML={{ __html: trimEmptyTags(feature.title) }}
				></Card.Title>
				<Card.Text
					className='text-limit text-break'
					style={{
						color: '#808191',
						marginBottom: '0',
						lineHeight: '1.6',
						'--lines': 3,
					}}
					dangerouslySetInnerHTML={{
						__html: trimEmptyTags(feature.description),
					}}
				></Card.Text>
			</Card.Body>
		</Card>
	);
};

export default FeatureCardComponent;

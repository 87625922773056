import { createSlice } from '@reduxjs/toolkit';

// Slice

export const featuresSlice = createSlice({
	name: 'features',
	initialState: {
		features: [],
		isFeaturesLoading: true,
	},
	reducers: {
		featuresSuccess: (state, action) => {
			state.features = action.payload;
			state.isFeaturesLoading = false;
		},

		startLoading: (state) => {
			state.isFeaturesLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isFeaturesLoading = false;
		},
	},
});

export default featuresSlice.reducer;

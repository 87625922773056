import axios from 'axios';
import { testimonialsSlice } from '../reducers/testimonials.reducer';

const { testimonialsSuccess, startLoading, hasError } =
	testimonialsSlice.actions;

// Actions
export const fetchTestimonials =
	(
		url = 'https://demoyoursite.net/ibrahim/artvision-backend/public/api/testimonials',
		language = 'en'
	) =>
	async (dispatch) => {
		dispatch(startLoading());

		await axios({
			method: 'get',
			url: url,
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				dispatch(testimonialsSuccess(response.data.data));
			})
			.catch((error) => dispatch(hasError(error.message)));
	};

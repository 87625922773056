import { createSlice } from '@reduxjs/toolkit';

// Slice

export const coursesSlice = createSlice({
	name: 'courses',
	initialState: {
		courses: [],
		levels: {},
		prices: [],
		isCoursesLoading: true,
	},
	reducers: {
		coursesSuccess: (state, action) => {
			state.courses = action.payload.data;
			state.levels = action.payload.levels;
			state.prices = Object.values(action.payload.prices);
			state.isCoursesLoading = false;
		},

		startLoading: (state) => {
			state.isCoursesLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isCoursesLoading = false;
		},
	},
});

export default coursesSlice.reducer;

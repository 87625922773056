import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ar';
import React, { useEffect, useState } from 'react';
import {
	Card,
	Col,
	Container,
	Form,
	FormGroup,
	Image,
	Modal,
	OverlayTrigger,
	Popover,
	Row,
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCalendarDay, FaShare, FaUserAlt } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';
import { ToastContainer } from 'react-toastify';
import { calcTotalHours } from '../helpers/calcTotalHours';
import { constructURL } from '../helpers/constructURL';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import 'react-phone-number-input/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/CourseDetailsPage.styles.css';
import '../styles/MobileNumberComponent.styles.css';

// Components
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import CourseDetailsOverviewComponent from '../components/CourseDetailsOverviewComponent';
import IconTextComponent from '../components/IconTextComponent';
import InstructorAvatarComponent from '../components/InstructorAvatarComponent';
import LoadingPageComponent from '../components/LoadingPageComponent';
import RatingStarsComponent from '../components/RatingStarsComponent';
import CourseSubscriptionComponent from './../components/CourseSubscriptionComponent';

const CourseDetailsPage = ({ locale }) => {
	locale ? moment.locale('en') : moment.locale('ar');

	// i18n
	const { lang, section_id, course_id } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	const shareURL = window.location.href;

	// States
	const [course, setCourse] = useState(null);
	const [section, setSection] = useState(null);
	const [copyToClipboard, setCopyToClipboard] = useState(false); // eslint-disable-line

	const [shareModalShow, setShareModalShow] = useState(false);

	const breadcrumbItems = course &&
		section && [
			{
				title: t('breadcrumb.home', { ns: 'words' }),
				href: `/${lang}`,
				isActive: false,
			},
			{
				title: section.title,
				href: `/${lang}/section/${section_id}`,
				isActive: false,
			},
			{
				title: course.category.title,
				href: `/${lang}/section/${section_id}/category/${course.category.id}`,
				isActive: false,
			},
			{
				title: t('breadcrumb.courseDetails', { ns: 'words' }),
				href: '',
				isActive: true,
			},
		];

	// Fetch Course Data
	const getCourseData = async (language = 'en') => {
		axios
			.get(constructURL(`courses/${course_id}`), {
				headers: { locale: language },
			})
			.then((response) => {
				setCourse(response.data.data);
			});
	};

	const getSectionData = async (language = 'en') => {
		axios
			.get(constructURL(`sections/${section_id}`), {
				headers: { locale: language },
			})
			.then((response) => {
				setSection(response.data.data);
			});
	};

	// Render Initial Course
	useEffect(() => {
		setCourse(null);
		setSection(null);

		getCourseData(locale ? 'en' : 'ar');
		getSectionData(locale ? 'en' : 'ar');

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	return (
		<section
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			id='course-details'
			style={{
				minHeight: '90vh',
				padding: '5rem 0',
				overflow: 'visible',
			}}
		>
			{/* Toast Messages */}
			<ToastContainer
				position='top-right'
				autoClose={10000}
				newestOnTop
				pauseOnHover
				rtl={locale ? false : true}
			/>

			{!course ? (
				<LoadingPageComponent />
			) : (
				<>
					{/* Breadcrumb */}
					{course && section && (
						<BreadcrumbComponent
							title={course.title}
							items={
								course.category
									? breadcrumbItems
									: breadcrumbItems.filter(
											(_, index) => index !== breadcrumbItems.length - 2
									  )
							}
						/>
					)}

					<Container fluid='md'>
						<Row className='g-4'>
							<Col xs={12} lg={8}>
								<Row xs={1} className='g-4'>
									<Col>
										{/* Course Preview Media */}
										<Image
											src={
												course.image ??
												require('../assets/images/logos/logo.png')
											}
											fluid
											className='course-preview rounded-2 w-100'
											style={{
												objectFit: 'cover',
												objectPosition: 'center',
												height: '28rem',
											}}
										/>
									</Col>

									{/* Course Title */}
									<Col>
										<h5
											className='display-6 p-0 m-0'
											style={{
												fontSize: '24px',
												fontWeight: '500',
												color: '#11142d',
											}}
										>
											{course.title}
										</h5>
									</Col>

									{/* Course Description */}
									<Col>
										<p
											className='text-muted'
											style={{
												fontSize: '1rem',
											}}
										>
											{course.description}
										</p>
									</Col>

									<Col>
										<Row xs={1} sm={2} xxl={4} className='g-4'>
											{/* Instructor */}
											<Col className='d-flex align-items-center justify-content-center'>
												<InstructorAvatarComponent
													image={course.instructor.image}
													name={course.instructor.full_name}
													styles={{
														text: {
															fontSize: '14px',
														},
														image: {
															width: '26px',
															height: '26px',
														},
													}}
												/>
											</Col>

											{/* Last Update */}
											<Col className='d-flex align-items-center justify-content-center'>
												<IconTextComponent
													icon={<FaCalendarDay size={16} />}
													label={t('courseDetailsPage.lastUpdated', {
														ns: 'words',
													})}
													text={moment(new Date(course.updated_at)).format(
														// locale ? 'DD/MM/YYYY' : 'YYYY/MM/DD'
														'l'
													)}
												/>
											</Col>

											{/* Total Enrolled Students */}
											<Col className='d-flex align-items-center justify-content-center'>
												<IconTextComponent
													icon={<FaUserAlt size={16} />}
													text={t('courseDetailsPage.alreadyEnrolled', {
														ns: 'words',
														val: course.total_students,
													})}
												/>
											</Col>

											{/* Rating */}
											<Col className='d-flex align-items-center justify-content-center'>
												<RatingStarsComponent
													locale={locale}
													initialRating={course.rating}
													reviewersCount={course.total_reviewers}
												/>
											</Col>
										</Row>
									</Col>

									<Col>
										<Row xs={2} sm={3} className='g-4'>
											{/* Price */}
											<Col className='d-flex align-items-center justify-content-center'>
												<span
													style={{
														color: 'rgb(0, 113, 220)',
														fontSize: '18px',
														fontWeight: '600',
													}}
												>{`$${course.price.toLocaleString('en')}`}</span>
											</Col>

											{/* Enroll Now */}
											<Col className='d-flex align-items-center justify-content-center'>
												<CourseSubscriptionComponent
													locale={locale}
													lang={lang}
													course={course}
												/>
											</Col>

											{/* Share Course */}
											<Col className='d-flex align-items-center justify-content-center'>
												<IconTextComponent
													className='share-btn'
													icon={<FaShare size={20} />}
													text={t('share', { ns: 'words' })}
													textStyles={{
														fontSize: '16px',
														fontWeight: '500',
													}}
													onClick={() => setShareModalShow(true)}
												/>

												{/* Course Share */}
												<Modal
													show={shareModalShow}
													onHide={() => {
														setShareModalShow(false);
														setCopyToClipboard(false);
													}}
													size='md'
													aria-labelledby='contained-modal-title-vcenter'
													centered
												>
													<Modal.Header closeButton>
														<Modal.Title className='text-capitalize'>
															{t('shareVia', { ns: 'words' })}
														</Modal.Title>
													</Modal.Header>

													<Modal.Body className='p-0'>
														<>
															<div
																className='d-flex justify-content-center align-items-center'
																style={{
																	padding: '0.375rem 0.75rem',
																}}
															>
																<WhatsappShareButton
																	url={shareURL}
																	className='share-icon-container'
																>
																	<WhatsappIcon size={50} round={true} />
																	<div className='share-icon-title'>
																		{t('socials.whatsapp', { ns: 'words' })}
																	</div>
																</WhatsappShareButton>

																<FacebookShareButton
																	url={shareURL}
																	className='share-icon-container'
																>
																	<FacebookIcon size={50} round={true} />
																	<div className='share-icon-title'>
																		{t('socials.facebook', { ns: 'words' })}
																	</div>
																</FacebookShareButton>

																<TwitterShareButton
																	url={shareURL}
																	className='share-icon-container'
																>
																	<TwitterIcon size={50} round={true} />
																	<div className='share-icon-title'>
																		{t('socials.twitter', { ns: 'words' })}
																	</div>
																</TwitterShareButton>

																<EmailShareButton
																	url={shareURL}
																	className='share-icon-container'
																>
																	<EmailIcon size={50} round={true} />
																	<div className='share-icon-title'>
																		{t('socials.email', { ns: 'words' })}
																	</div>
																</EmailShareButton>
															</div>
															<FormGroup
																as={Col}
																className='mb-3 position-relative'
															>
																<Form.Text
																	className='form-control'
																	style={{
																		backgroundColor: '#e9ecef',
																		paddingTop: '1rem',
																		paddingBottom: '1rem',
																	}}
																>
																	{window.location.href}
																</Form.Text>
																<CopyToClipboard
																	text={window.location.href}
																	onCopy={() => setCopyToClipboard(true)}
																>
																	<OverlayTrigger
																		trigger='click'
																		placement='top'
																		overlay={
																			<Popover id='popover-basic'>
																				<Popover.Body
																					dir={locale ? 'ltr' : 'rtl'}
																					lang={locale ? 'en' : 'ar'}
																					className='text-capitalize py-1'
																					style={{
																						color: 'rgb(0, 113, 220)',
																					}}
																				>
																					{t('copied', { ns: 'words' })}
																				</Popover.Body>
																			</Popover>
																		}
																	>
																		<div className='copy-icon'>
																			<FiCopy size={30} />
																		</div>
																	</OverlayTrigger>
																</CopyToClipboard>
															</FormGroup>
														</>
													</Modal.Body>
												</Modal>
											</Col>
										</Row>

										{/* Course Details */}
										<Row
											style={{
												paddingTop: '3rem',
												marginTop: '-15px',
											}}
										>
											<CourseDetailsOverviewComponent
												locale={locale}
												course={course}
											/>
										</Row>
									</Col>
								</Row>
							</Col>

							{/* Course Details */}
							<Col xs={12} lg={4} className='position-relative'>
								<Card
									bg='light'
									text='dark'
									className='overflow-hidden'
									style={{
										minHeight: '28rem',
										position: 'sticky',
										top: '8rem',
									}}
								>
									<Card.Header
										as='h5'
										className='d-flex justify-content-between align-items-center'
										style={{
											color: '#ffffff',
											backgroundColor: '#060606',
										}}
									>
										{/* Card Title */}
										<span className='text-capitalize'>
											{t('courseDetailsPage.courseDetails', { ns: 'words' })}
										</span>
									</Card.Header>
									{course && section && (
										<Card.Body
											style={{
												color: '#232323',
											}}
										>
											{/* Level */}
											<Card.Text>
												<span className='detail-title'>
													{`${t('courseDetailsPage.level', {
														ns: 'words',
													})}:`}
												</span>
												<span className='text-capitalize'>
													{course.level.title}
												</span>
											</Card.Text>

											{/* Category */}
											<Card.Text>
												<span className='detail-title'>
													{`${t('courseDetailsPage.category', {
														ns: 'words',
													})}:`}
												</span>
												<span className='text-capitalize'>
													{course.category.title}
												</span>
											</Card.Text>

											{/* Total hours */}
											<Card.Text>
												<span className='detail-title'>
													{`${t('courseDetailsPage.totalHours', {
														ns: 'words',
													})}:`}
												</span>
												<span className='text-capitalize'>
													{`${calcTotalHours(course.total_minutes).hours}h ${
														calcTotalHours(course.total_minutes).minutes
													}m`}
												</span>
											</Card.Text>

											{/* Total Lessons */}
											<Card.Text>
												<span className='detail-title'>
													{`${t('courseDetailsPage.totalLessons', {
														ns: 'words',
													})}:`}
												</span>
												<span className='text-capitalize'>
													{course.total_lessons}
												</span>
											</Card.Text>

											{/* Total Students */}
											<Card.Text>
												<span className='detail-title'>
													{`${t('courseDetailsPage.totalStudents', {
														ns: 'words',
													})}:`}
												</span>
												<span className='text-capitalize'>
													{course.total_students}
												</span>
											</Card.Text>

											{/* Last Update */}
											<Card.Text>
												<span className='detail-title'>
													{`${t('courseDetailsPage.lastUpdated', {
														ns: 'words',
													})}:`}
												</span>
												<span className='text-capitalize'>
													{moment(new Date(course.updated_at)).format(
														// locale ? 'DD/MM/YYYY' : 'YYYY/MM/DD'
														'l'
													)}
												</span>
											</Card.Text>
										</Card.Body>
									)}
								</Card>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</section>
	);
};

export default CourseDetailsPage;

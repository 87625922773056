import React from 'react';
import { Card } from 'react-bootstrap';
import { IoColorPalette } from 'react-icons/io5';
import { trimEmptyTags } from './../helpers/trimEmptyTags';

// Styles
import '../styles/AboutCardComponent.styles.css';

const AboutCardComponent = ({ locale, index, about }) => {
	return (
		<Card
			className='about-card position-static'
			style={{
				'--background-image': `url(${about.image})`,
			}}
		>
			<Card.Body>
				<Card.Title className='about-title'>
					<IoColorPalette
						size={24}
						color='#0d6efd'
						className={`${locale ? 'me-2' : 'ms-2'}`}
					/>
					<span>{about.title}</span>
				</Card.Title>
				<Card.Text
					className='about-paragraph'
					dangerouslySetInnerHTML={{
						__html: trimEmptyTags(about.description),
					}}
				></Card.Text>
			</Card.Body>

			{/* Card Connectors */}
			<Card.Text className='bottom-line'></Card.Text>
			<Card.Text
				className={`bottom ${
					index % 2 === 0
						? locale
							? 'left'
							: 'right'
						: locale
						? 'right'
						: 'left'
				}`}
			></Card.Text>
			<Card.Text
				className={`top ${
					index % 2 === 0
						? locale
							? 'left'
							: 'right'
						: locale
						? 'right'
						: 'left'
				}`}
			></Card.Text>
		</Card>
	);
};

export default AboutCardComponent;

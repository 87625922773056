import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
	Col,
	FormGroup,
	FormLabel,
	FormSelect,
	Modal,
	OverlayTrigger,
	Popover,
	Row,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { constructURL } from '../helpers/constructURL';
import useUserData from '../hooks/useUserData';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import '../styles/MobileNumberComponent.styles.css';

// Components
import ButtonComponent from './ButtonComponent';

const CourseSubscriptionComponent = ({ locale, course }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	// eslint-disable-next-line
	const { userData, setUserData } = useUserData();

	const [subscribeModalShow, setSubscribeModalShow] = useState(false);
	const [countries, setCountries] = useState(null);

	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const countryRef = useRef();

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				phoneRef.current.classList.add('is-invalid');
				break;

			case 'country':
				countryRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 400:
			case 403:
				toast.error(message);
				break;
			case 401:
				toast.error(t('loginFirst', { ns: 'sentences' }));
				break;
			default:
				toast.error(t('errors.default', { ns: 'sentences' }));
				break;
		}
	};

	const subscribeSchema = Yup.object().shape({
		fname: Yup.string()
			.min(2, t('firstName.min', { ns: 'validations', min: 2 }))
			.max(100, t('firstName.max', { ns: 'validations', max: 100 }))
			.required(t('firstName.required', { ns: 'validations' })),

		lname: Yup.string()
			.min(2, t('lastName.min', { ns: 'validations', min: 2 }))
			.max(100, t('lastName.max', { ns: 'validations', max: 100 }))
			.required(t('lastName.required', { ns: 'validations' })),

		email: Yup.string()
			.email(t('email.format', { ns: 'validations' }))
			.required(t('email.required', { ns: 'validations' })),

		phone: Yup.string()
			.min(6, t('phone.min', { ns: 'validations', min: 6 }))
			.matches(/^[0-9+]+/, t('phone.format', { ns: 'validations' }))
			.required(t('phone.required', { ns: 'validations' })),

		country: Yup.string()
			.min(2, t('country.min', { ns: 'validations', min: 2 }))
			.max(100, t('country.max', { ns: 'validations', max: 100 }))
			.required(t('country.required', { ns: 'validations' })),
	});

	const submitSubscriptionForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		axios({
			method: 'post',
			url: constructURL('course-subscription'),
			data: {
				course_id: course.id,
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				phone: values.phone,
				country: values.country,
			},
			headers: {
				locale: language,
				Authorization: userData && `Bearer ${userData.token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);

				// setUserData({
				// 	...userData,
				// 	courses: [...userData.courses, course.id],
				// });

				displayToast(response.status, response.data.message);
				setSubscribeModalShow(false);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);
				if (error.response.status === 401) {
					displayToast(error.response.status, error.response.data);
					// setTimeout(() => {
					// 	sessionStorage.setItem('beforeLogin', location.pathname);
					// 	navigate(`/${lang}/login`);
					// 	window.location.reload();
					// }, 4000);
				} else if (error.response.data.data.length !== 0) {
					Object.keys(error.response.data.data).forEach((key) => {
						displayErrors(key);
						displayToast(
							error.response.status,
							error.response.data.data[key][0]
						);
					});
				} else {
					displayToast(error.response.status, error.response.data.message);
				}
			});
	};

	// Get countries
	useEffect(() => {
		const countries = require('localized-countries')(
			require(`localized-countries/data/${lang}`)
		);
		setCountries(countries.array());

		// eslint-disable-next-line
	}, [locale]);

	return (
		<>
			<OverlayTrigger
				trigger={['hover', 'focus']}
				placement='top'
				overlay={
					!userData ? (
						<></>
					) : userData.enrolled_courses.filter(
							(courseID) => courseID === course.id
					  ).length > 0 ? (
						<Popover
							dir={locale ? 'ltr' : 'rtl'}
							lang={locale ? 'en' : 'ar'}
							id='popover-basic'
						>
							<Popover.Header as='h3'>
								{t('attention', { ns: 'words' })}
							</Popover.Header>
							<Popover.Body>
								{t('alreadyEnrolled', { ns: 'sentences' })}
							</Popover.Body>
						</Popover>
					) : (
						<></>
					)
				}
			>
				<span className='d-inline-block'>
					<ButtonComponent
						title={
							!userData
								? t('buttons.enroll', { ns: 'words' })
								: userData.enrolled_courses.filter(
										(courseID) => courseID === course.id
								  ).length === 0
								? t('buttons.enroll', { ns: 'words' })
								: t('buttons.alreadyEnroll', { ns: 'words' })
						}
						disabled={
							!userData
								? false
								: userData.enrolled_courses.filter(
										(courseID) => courseID === course.id
								  ).length > 0
								? true
								: false
						}
						onClick={() => setSubscribeModalShow(true)}
					/>

					{/* Course Subscription */}
					<Modal
						dir={locale ? 'ltr' : 'rtl'}
						lang={locale ? 'en' : 'ar'}
						show={subscribeModalShow}
						onHide={() => setSubscribeModalShow(false)}
						size='lg'
						aria-labelledby='contained-modal-title-vcenter'
						centered
					>
						<Modal.Body className='p-0'>
							<Formik
								initialValues={{
									fname: userData ? userData.fname : '',
									lname: userData ? userData.lname : '',
									email: userData ? userData.email : '',
									phone: userData ? userData.phone : '',
									country: '',
								}}
								validationSchema={subscribeSchema}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									setSubmitting(true);
									submitSubscriptionForm(
										values,
										setSubmitting,
										resetForm,
										lang === 'en' ? 'en' : 'ar'
									);
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setFieldValue,
								}) => (
									<Form
										onSubmit={(event) => {
											event.preventDefault();

											// Check login token
											// if (!userData) {
											// 	sessionStorage.setItem(
											// 		'beforeLogin',
											// 		location.pathname
											// 	);
											// 	navigate(`/${lang}/login`);
											// 	window.location.reload();
											// }

											handleSubmit();
										}}
										className='p-4 overflow-hidden'
										style={{
											backgroundColor: '#f9fafd',
											border: '1px solid #e5f1fb',
											borderRadius: '0.375rem',
										}}
									>
										<Row xs={1} sm={2}>
											{/* First Name */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													locale
														? 'animate__fadeInLeft'
														: 'animate__fadeInRight'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.25s',
												}}
											>
												<FormLabel
													htmlFor='first_name'
													className='text-capitalize'
												>
													{t('labels.firstName', { ns: 'words' })}
												</FormLabel>
												<Field
													id='first_name'
													type='text'
													innerRef={firstNameRef}
													placeholder={t('placeholders.firstName', {
														ns: 'words',
													})}
													autoComplete='off'
													name='fname'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={handleBlur}
													value={values.fname}
													className={`form-control text-capitalize ${
														touched.fname && errors.fname ? 'is-invalid' : ''
													}`}
												/>
												<ErrorMessage
													component='div'
													name='fname'
													className='invalid-feedback'
												/>
											</FormGroup>

											{/* Last Name */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													locale
														? 'animate__fadeInRight'
														: 'animate__fadeInLeft'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.25s',
												}}
											>
												<FormLabel
													htmlFor='last_name'
													className='text-capitalize'
												>
													{t('labels.lastName', { ns: 'words' })}
												</FormLabel>
												<Field
													id='last_name'
													type='text'
													innerRef={lastNameRef}
													placeholder={t('placeholders.lastName', {
														ns: 'words',
													})}
													autoComplete='off'
													name='lname'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={handleBlur}
													value={values.lname}
													className={`form-control text-capitalize ${
														touched.lname && errors.lname ? 'is-invalid' : ''
													}`}
												/>
												<ErrorMessage
													component='div'
													name='lname'
													className='invalid-feedback'
												/>
											</FormGroup>

											{/* Email */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													locale
														? 'animate__fadeInLeft'
														: 'animate__fadeInRight'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.5s',
												}}
											>
												<FormLabel htmlFor='email' className='text-capitalize'>
													{t('labels.email', { ns: 'words' })}
												</FormLabel>
												<Field
													id='email'
													type='email'
													innerRef={emailRef}
													placeholder='mail@domain.com'
													autoComplete='off'
													name='email'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={handleBlur}
													value={values.email}
													className={`form-control ${
														touched.email && errors.email ? 'is-invalid' : ''
													}`}
												/>
												<ErrorMessage
													component='div'
													name='email'
													className='invalid-feedback'
												/>
											</FormGroup>

											{/* Mobile Number */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													locale
														? 'animate__fadeInRight'
														: 'animate__fadeInLeft'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.5s',
												}}
											>
												<FormLabel htmlFor='phone'>
													{t('labels.phone', { ns: 'words' })}
												</FormLabel>
												<Field name='phone' innerRef={phoneRef}>
													{(field, form, meta) => (
														<>
															<PhoneInput
																{...field}
																id='phone'
																dir='ltr'
																ref={phoneRef}
																placeholder='56 123 0620'
																defaultCountry='SA'
																autoComplete='off'
																onChange={(event) => {
																	setFieldValue('phone', event);
																}}
																onBlur={handleBlur}
																value={values.phone}
																className={`${
																	field.meta.touched && field.meta.error
																		? 'is-invalid'
																		: ''
																}`}
															/>
															{field.meta.error && (
																<div className='invalid-feedback'>
																	{field.meta.error}
																</div>
															)}
														</>
													)}
												</Field>
											</FormGroup>

											{/* Country */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													locale
														? 'animate__fadeInLeft'
														: 'animate__fadeInRight'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.75s',
												}}
											>
												<FormLabel
													htmlFor='country'
													className='text-capitalize'
												>
													{t('labels.country', { ns: 'words' })}
												</FormLabel>
												<FormSelect
													id='country'
													ref={countryRef}
													value={values.country}
													name='country'
													className='text-capitalize'
													onChange={(event) =>
														setFieldValue('country', event.target.value)
													}
												>
													<option hidden value=''>
														{t('placeholders.country', { ns: 'words' })}
													</option>
													{countries &&
														countries.map((country) => (
															<option
																key={country.code}
																value={country.label}
																style={{
																	color: '#11142d',
																}}
															>
																{country.label}
															</option>
														))}
												</FormSelect>
												<ErrorMessage
													component='div'
													name='country'
													className='invalid-feedback'
												/>
											</FormGroup>
										</Row>

										{/* Submit Form */}
										<FormGroup
											className='d-flex justify-content-center mt-3 animate__animated animate__zoomIn animate__delay-1s'
											style={{
												'--animate-delay': '1s',
											}}
										>
											<OverlayTrigger
												trigger={['hover', 'focus']}
												placement='top'
												overlay={
													!userData ? (
														<></>
													) : userData.enrolled_courses.filter(
															(courseID) => courseID === course.id
													  ).length > 0 ? (
														<Popover
															dir={locale ? 'ltr' : 'rtl'}
															lang={locale ? 'en' : 'ar'}
															id='popover-basic'
														>
															<Popover.Header as='h3'>
																{t('attention', { ns: 'words' })}
															</Popover.Header>
															<Popover.Body>
																{t('alreadyEnrolled', { ns: 'sentences' })}
															</Popover.Body>
														</Popover>
													) : (
														<></>
													)
												}
											>
												<span className='d-inline-block'>
													<ButtonComponent
														title={
															isSubmitting
																? t('buttons.sending', { ns: 'words' })
																: t('buttons.enroll', { ns: 'words' })
														}
														type='submit'
														disabled={
															!userData
																? false
																: userData.enrolled_courses.filter(
																		(courseID) => courseID === course.id
																  ).length > 0
																? true
																: false
														}
													/>
												</span>
											</OverlayTrigger>
										</FormGroup>
									</Form>
								)}
							</Formik>
						</Modal.Body>
					</Modal>
				</span>
			</OverlayTrigger>
		</>
	);
};

export default CourseSubscriptionComponent;

import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import aboutData from '../reducers/aboutData.reducer';
import categories from '../reducers/categories.reducer';
import certificates from '../reducers/certificates.reducer';
import courses from '../reducers/courses.reducer';
import features from '../reducers/features.reducer';
import footerData from '../reducers/footerData.reducer';
import homeData from '../reducers/homeData.reducer';
import months from '../reducers/months.reducer';
import sections from '../reducers/sections.reducer';
import settingsData from '../reducers/settingsData.reducer';
import testimonials from '../reducers/testimonials.reducer';

const reducer = combineReducers({
	sections,
	categories,
	courses,
	months,
	certificates,
	features,
	testimonials,
	homeData,
	aboutData,
	settingsData,
	footerData,
});

const store = configureStore({
	reducer,
});

export default store;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { trimEmptyTags } from '../helpers/trimEmptyTags';

// Styles:
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/CourseDetailsOverviewComponent.styles.css';

const CourseDetailsOverviewComponent = ({ locale, course, reviews }) => {
	return (
		<Row xs={1} className='gy-4' id='course-overview'>
			{/* Course Details */}
			<Col>
				<article id='course-overview-details'>
					<p
						dangerouslySetInnerHTML={{
							__html: course.details,
						}}
						style={{
							color: '#808191',
						}}
					></p>
				</article>
			</Col>

			{/* What Will I Learn? */}
			<Col>
				<article
					id='course-overview-objectives'
					style={{
						backgroundColor: '#f9fafd',
						padding: '30px 30px 10px',
					}}
				>
					<ul
						dangerouslySetInnerHTML={{
							__html: trimEmptyTags(course.learning_objectives),
						}}
					></ul>
				</article>
			</Col>

			{/* Requirements */}
			<Col>
				<article id='course-overview-requirements'>
					<ul
						dangerouslySetInnerHTML={{
							__html: trimEmptyTags(course.requirements),
						}}
					></ul>
				</article>
			</Col>

			{/* Skills */}
			<Col>
				<article id='course-overview-skills'>
					<ul
						dangerouslySetInnerHTML={{
							__html: trimEmptyTags(course.skills_covered),
						}}
					></ul>
				</article>
			</Col>
		</Row>
	);
};

export default CourseDetailsOverviewComponent;

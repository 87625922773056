import { Formik } from 'formik';
import 'moment/locale/ar';
import React, { useEffect } from 'react';
import {
	CloseButton,
	Col,
	Form,
	FormControl,
	FormGroup,
	FormLabel,
	Modal,
	Row,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import '../styles/SearchModalComponent.styles.css';

// Components
import ButtonComponent from './ButtonComponent';

const SearchModalComponent = ({ locale, ...props }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	const navigate = useNavigate();

	const { sections, isSectionsLoading } = useSelector(
		(state) => state.sections
	);
	const { categories, isCategoriesLoading } = useSelector(
		(state) => state.categories
	);

	const searchSchema = Yup.object().shape({});

	const submitRegistrationForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		sessionStorage.setItem('searchTerms', JSON.stringify(values));
		props.onHide();
		navigate(`/${lang}/search`);
		window.location.reload();
	};

	return isSectionsLoading || isCategoriesLoading ? (
		<></>
	) : (
		<Modal
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			id='search-modal'
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered
		>
			<Modal.Header>
				<Modal.Title
					id='contained-modal-title-vcenter'
					className='text-capitalize'
				>
					{t('searchModalTitle', { ns: 'sentences' })}
				</Modal.Title>
				<CloseButton
					className={`close-modal mx-0 ${locale ? 'ms-auto' : 'me-auto'}`}
					aria-label='Hide'
					onClick={props.onHide}
				/>
			</Modal.Header>
			<Modal.Body>
				<Formik
					initialValues={{
						title: '',
						section_id: '',
						category_id: '',
					}}
					validationSchema={searchSchema}
					onSubmit={(values, { setSubmitting, resetForm }) => {
						setSubmitting(true);
						submitRegistrationForm(
							values,
							setSubmitting,
							resetForm,
							lang === 'en' ? 'en' : 'ar'
						);
					}}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						isSubmitting,
						setFieldValue,
					}) => (
						<>
							{/* Search Form */}
							<Form
								onSubmit={(event) => {
									event.preventDefault();
									handleSubmit();
								}}
								className='p-4 overflow-hidden'
							>
								<Row xs={1} className='overflow-hidden'>
									{/* Title Filter */}
									<FormGroup
										as={Col}
										className={`mb-3 animate__animated animate__fadeInDown animate__delay-1s`}
										style={{
											'--animate-delay': '0.25s',
										}}
									>
										<FormLabel
											htmlFor='course_title'
											className='text-capitalize'
										>
											{t('labels.courseKeywords', { ns: 'words' })}
										</FormLabel>
										<FormControl
											id='course_title'
											type='text'
											placeholder={t('placeholders.courseKeywords', {
												ns: 'words',
											})}
											autoComplete='off'
											name='title'
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.title}
											className={`text-capitalize ${
												touched.title && errors.title ? 'is-invalid' : ''
											}`}
										/>
									</FormGroup>
								</Row>

								<Row xs={1} sm={2} className='overflow-hidden'>
									{/* Sections Filter */}
									<FormGroup
										className={`mb-3 animate__animated ${
											locale ? 'animate__fadeInLeft' : 'animate__fadeInRight'
										} animate__delay-1s`}
										style={{
											'--animate-delay': '0.5s',
										}}
									>
										<FormLabel
											className='group-title text-capitalize'
											htmlFor='course_section'
										>
											{t('labels.section', { ns: 'words' })}
										</FormLabel>

										<Form.Select
											id='course_section'
											value={values.section_id}
											name='section_id'
											className='text-capitalize'
											onChange={(event) => {
												setFieldValue(
													'section_id',
													Array.from(event.currentTarget.children)
														.slice(1)
														.filter(
															(option) => event.target.value === option.value
														)[0].value
												);
											}}
										>
											<option hidden>
												{t('placeholders.section', { ns: 'words' })}
											</option>

											{!isSectionsLoading &&
												sections.map((section) => (
													<option
														key={section.title}
														value={section.id}
														style={{
															color: '#11142d',
														}}
													>
														{section.title}
													</option>
												))}
										</Form.Select>
									</FormGroup>

									{/* Categories Filter */}
									<FormGroup
										className={`mb-3 animate__animated ${
											locale ? 'animate__fadeInRight' : 'animate__fadeInLeft'
										} animate__delay-1s`}
										style={{
											'--animate-delay': '0.5s',
										}}
									>
										<FormLabel
											className='group-title text-capitalize'
											htmlFor='course_category'
										>
											{t('labels.category', { ns: 'words' })}
										</FormLabel>

										<Form.Select
											id='course_category'
											value={values.category_id}
											name='category_id'
											className='text-capitalize'
											onChange={(event) =>
												setFieldValue('category_id', event.target.value)
											}
											disabled={
												!isCategoriesLoading &&
												categories.filter(
													(category) =>
														+category.section_id === +values.section_id
												).length === 0
													? true
													: false
											}
										>
											{!isCategoriesLoading &&
											categories.filter(
												(category) =>
													+category.section_id === +values.section_id
											).length === 0 ? (
												values.section_id === '' ? (
													<option hidden>
														{t('placeholders.sectionFirst', {
															ns: 'sentences',
														})}
													</option>
												) : (
													<option
														style={{
															color: '#11142d',
														}}
													>
														{t('placeholders.noCategoryNeeded', {
															ns: 'sentences',
														})}
													</option>
												)
											) : (
												<>
													<option hidden>
														{t('placeholders.category', { ns: 'words' })}
													</option>

													{!isCategoriesLoading &&
														categories
															.filter(
																(category) =>
																	+category.section_id === +values.section_id
															)
															.map((category) => (
																<option
																	key={category.title}
																	value={category.id}
																	style={{
																		color: '#11142d',
																	}}
																>
																	{category.title}
																</option>
															))}
												</>
											)}
										</Form.Select>
									</FormGroup>
								</Row>

								{/* Submit Form */}
								<FormGroup
									className='d-flex justify-content-center mt-3 animate__animated animate__zoomIn animate__delay-1s'
									style={{
										'--animate-delay': '1s',
									}}
								>
									<ButtonComponent
										title={
											isSubmitting
												? t('buttons.searching', { ns: 'words' })
												: t('buttons.searchCourses', { ns: 'words' })
										}
										type='submit'
										styles={{
											button: {
												padding: '0.5rem 1rem',
											},
											title: {
												fontWeight: '200',
												fontSize: '16px',
											},
										}}
									/>
								</FormGroup>
							</Form>
						</>
					)}
				</Formik>
			</Modal.Body>
		</Modal>
	);
};

export default SearchModalComponent;

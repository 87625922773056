import '@fontsource/marhey';
import '@fontsource/merienda';
import AOS from 'aos';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './i18n';
import store from './store/index.store';

// Styles
import 'animate.css';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'normalize.css';
import './index.css';

// Components
import LoadingPageComponent from './components/LoadingPageComponent';

AOS.init({
	duration: 1000,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<React.StrictMode>
			<Suspense fallback={<LoadingPageComponent />}>
				<App />
			</Suspense>
		</React.StrictMode>
	</Provider>
);

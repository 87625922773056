import React from 'react';
import { Rating } from 'react-simple-star-rating';

import '../styles/RatingStarsComponent.styles.css';

const calcRatingValue = (ratings, initialRating) => {
	let ratingValue = initialRating;
	ratings.forEach((rating) => {
		ratingValue += +rating;
	});

	return ratings.length > 0 ? ratingValue / ratings.length : ratingValue;
};

const RatingStarsComponent = ({
	locale,
	reviewersCount = 1,
	initialRating = 0,
	starsCount = 5,
	ratings = [],
}) => {
	const STAR_COLOR = '#f1a545';

	const rating = calcRatingValue(ratings, initialRating);

	return (
		<div className='rating-stars d-flex justify-content-center align-items-center'>
			{/* Rating */}
			<Rating
				className={`m-0 p-0 ${starsCount === 1 ? 'stars' : ''}`}
				initialValue={rating}
				iconsCount={starsCount}
				rtl={locale ? false : true}
				allowFraction={true}
				readonly
				size={20}
			/>

			<span
				className='mx-1 d-flex justify-content-center align-items-center'
				style={{
					color: STAR_COLOR,
					fontSize: '14px',
					fontWeight: '500',
				}}
			>
				{parseFloat(rating).toFixed(1)}
			</span>

			<span
				className='d-flex justify-content-center align-items-center'
				style={{
					color: '#808191',
					fontSize: '14px',
					fontWeight: '500',
				}}
			>
				({reviewersCount})
			</span>
		</div>
	);
};

export default RatingStarsComponent;

import { createSlice } from '@reduxjs/toolkit';

export const homeDataSlice = createSlice({
	name: 'homeData',
	initialState: {
		homeData: {},
		trendingCourses: [],
		trendingCategories: [],
		mostEnrolledCourses: [],
		isHomeDataLoading: true,
	},
	reducers: {
		homeDataSuccess: (state, action) => {
			state.homeData = action.payload;
			state.trendingCourses = action.payload['trending']['courses'];
			state.trendingCategories = action.payload['trending']['categories'];
			state.mostEnrolledCourses = action.payload['most_enrolled'];
			state.isHomeDataLoading = false;
		},

		startLoading: (state) => {
			state.isHomeDataLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isHomeDataLoading = false;
		},
	},
});

export default homeDataSlice.reducer;

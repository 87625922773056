import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import '../styles/CategoryCardComponent.styles.css';

const CategoryCardComponent = ({ category, locale }) => {
	// i18n
	const { t, i18n } = useTranslation();

	return (
		<Card
			className='category-card text-center'
			style={{
				height: '12rem',
				'--card-image': `url(${
					category.image ?? require('../assets/images/logos/logo.png')
				})`,
			}}
		>
			<Card.ImgOverlay className='d-flex flex-column justify-content-center align-items-center'>
				{/* Category Title */}
				<Card.Title
					className='category-card-title text-capitalize text-limit'
					style={{
						lineHeight: '1.5',
						fontWeight: '500',
						fontSize: '18px',
						'--lines': 1,
					}}
				>
					{category.title}
				</Card.Title>

				{/* No. of Available Courses */}
				<Card.Text
					className='text-warning'
					style={{
						fontSize: '14px',
						fontWeight: '500',
						textShadow: '1px 1px 2px black',
						lineHeight: '1.2',
					}}
				>
					{category.courses_count > 0 ? (
						<span>
							{t('availableCourse', {
								ns: 'words',
								count: category.courses_count,
							})}
						</span>
					) : (
						<span>{t('availableCourse', { ns: 'words', count: 0 })}</span>
					)}
				</Card.Text>
			</Card.ImgOverlay>
		</Card>
	);
};

export default CategoryCardComponent;

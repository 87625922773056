import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import InstructorAvatarComponent from './InstructorAvatarComponent';
import RatingStarsComponent from './RatingStarsComponent';

// Styles
import '../styles/CourseCardComponent.styles.css';

const CourseCardComponent = ({ locale, course, category = {} }) => {
	const { lang } = useParams();

	const [badgeColor, setBadgeColor] = useState('black');

	useEffect(() => {
		if (course && course.level) {
			switch (course.level.id) {
				case 1:
					setBadgeColor('rgb(0, 113, 220)');
					break;

				case 3:
					setBadgeColor('#eb5757');
					break;

				case 4:
					setBadgeColor('#27ae60');
					break;

				default:
					break;
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	return (
		<>
			<Card
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				lang={lang === 'en' ? 'en' : 'ar'}
				className='course-card overflow-hidden text-dark'
				style={{
					border: '1px solid #f2f2f2',
				}}
			>
				{/* Level Title */}
				<span
					className='position-absolute course-badge text-capitalize'
					style={{
						backgroundColor: `${badgeColor}`,
						top: '15px',
						left: locale ? '20px' : 'unset',
						right: locale ? 'unset' : '20px',
					}}
				>
					{course.level.title}
				</span>

				{/* Course Image */}
				<div className='overflow-hidden'>
					<Link
						to={`/${lang}/section/${
							course.category ? course.category.section_id : category.section_id
						}/course/${course.id}`}
						target='_top'
						style={{
							textDecoration: 'none',
						}}
					>
						<Card.Img
							variant='top'
							src={course.image ?? require('../assets/images/logos/logo.png')}
							alt='Course Thumbnail'
							className='card-thumbnail img-fluid'
							style={{
								height: '12rem',
								objectFit: 'cover',
							}}
						/>
					</Link>
				</div>

				<Card.Body
					style={{
						padding: '15px 20px 0',
					}}
				>
					{/* Course Title */}
					<Link
						to={`/${lang}/section/${
							course.category ? course.category.section_id : category.section_id
						}/course/${course.id}`}
						target='_top'
						style={{
							textDecoration: 'none',
						}}
					>
						<Card.Title
							className='card-text text-limit m-0 p-0'
							style={{
								lineHeight: '1.5',
								fontSize: '18px',
								fontWeight: '500',
								'--lines': 1,
							}}
						>
							{course.title}
						</Card.Title>
					</Link>

					<Card.Text
						as='div'
						className='d-flex justify-content-between align-items-center pt-2'
						style={{
							paddingBottom: '15px',
							borderBottom: '1px solid #f2f2f2',
						}}
					>
						{/* Course Instructor */}
						<InstructorAvatarComponent
							image={course.instructor.image}
							name={course.instructor.full_name}
							styles={{
								text: {
									fontSize: '14px',
								},
								image: {
									width: '26px',
									height: '26px',
								},
							}}
						/>

						{/* Rating */}
						<RatingStarsComponent
							locale={locale}
							initialRating={course.rating}
							reviewersCount={course.total_reviewers}
							starsCount={1}
						/>
					</Card.Text>
				</Card.Body>

				<Card.Footer
					className='position-relative'
					style={{
						backgroundColor: 'transparent',
						borderTop: 'none',
						borderRadius: '0 0 8px 8px',
					}}
				>
					<Card.Text
						as='div'
						className='d-flex justify-content-between align-items-center'
					>
						{/* Category Title */}
						<Link
							to={`/${lang}/section/${
								course.category
									? course.category.section_id
									: category.section_id
							}/category/${course.category ? course.category.id : category.id}`}
							target='_top'
							className='d-block'
							style={{
								width: course.price ? '70%' : '100%',
								textDecoration: 'none',
							}}
						>
							<span
								className='card-text text-limit text-muted p-0 my-0 text-capitalize'
								style={{
									fontSize: '14px',
									fontWeight: '500',
									'--lines': 1,
								}}
							>
								{course.category ? course.category.title : category.title}
							</span>
						</Link>

						{/* Course Price */}
						<span
							style={{
								color: 'rgb(0, 113, 220)',
								fontSize: '18px',
								fontWeight: '500',
							}}
						>{`$${course.price.toLocaleString('en')}`}</span>
					</Card.Text>
				</Card.Footer>
			</Card>
		</>
	);
};

export default CourseCardComponent;

import React, { useEffect } from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAboutData } from '../actions/aboutData.action';
import AboutCardComponent from '../components/AboutCardComponent';
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import LoadingPageComponent from '../components/LoadingPageComponent';
import { constructURL } from '../helpers/constructURL';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import '../styles/AboutPage.styles.css';

const AboutPage = ({ locale }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('breadcrumb.home', { ns: 'words' }),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('breadcrumb.aboutUs', { ns: 'words' }),
			href: '',
			isActive: true,
		},
	];

	const dispatch = useDispatch();

	const { aboutData, isAboutDataLoading } = useSelector(
		(state) => state.aboutData
	);

	const { isSectionsLoading } = useSelector((state) => state.sections);

	// Render Initial About
	useEffect(() => {
		dispatch(fetchAboutData(constructURL('about-us'), locale ? 'en' : 'ar'));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	return (
		<section
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			id='about-us'
			style={{
				minHeight: '100vh',
				padding: '5rem 0',
			}}
		>
			{isSectionsLoading || isAboutDataLoading ? (
				<LoadingPageComponent />
			) : (
				<>
					{/* Breadcrumb */}
					<BreadcrumbComponent
						title={t('breadcrumb.aboutUs', { ns: 'words' })}
						items={breadcrumbItems}
					/>

					<Container fluid='md'>
						<Row xs={1} className='g-4'>
							{/* Main About Title */}
							<Fade direction='up' delay={50}>
								<Col>
									<h2
										className='display-5 fw-bold text-capitalize'
										style={{
											fontSize: '32px',
										}}
									>
										{t('companyTitle', { ns: 'words' })}
									</h2>
								</Col>
							</Fade>

							{/* Available About Sections */}
							<Col className='available-sections'>
								<Row
									xs={1}
									className='g-4 d-flex justify-content-center align-items-center overflow-hidden'
								>
									{aboutData.map((about, index) => (
										<Slide
											key={index}
											delay={index * 50}
											direction={
												index % 2 === 0
													? locale
														? 'left'
														: 'right'
													: locale
													? 'right'
													: 'left'
											}
											className='about-card-container position-relative d-flex align-items-center'
										>
											<Col className='d-flex align-items-center'>
												<AboutCardComponent
													locale={locale}
													index={index}
													about={about}
												/>
											</Col>
										</Slide>
									))}
								</Row>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</section>
	);
};

export default AboutPage;

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Pages
import AboutPage from './pages/AboutPage';
import CategoryDetailsPage from './pages/CategoryDetailsPage';
import ContactPage from './pages/ContactPage';
import CourseDetailsPage from './pages/CourseDetailsPage';
import HomePage from './pages/HomePage';
import MainTemplatePage from './pages/MainTemplatePage';
import NotFoundPage from './pages/NotFoundPage';
import SearchResultPage from './pages/SearchResultPage';
import SectionPage from './pages/SectionPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';

const App = () => {
	return (
		<BrowserRouter>
			{/* Available Routes */}
			<Routes>
				{/* Home Page */}
				<Route path='/' element={<Navigate to='/ar' />} />
				<Route
					path='/:lang'
					element={
						<MainTemplatePage>
							<HomePage />
						</MainTemplatePage>
					}
				/>

				{/* Sections */}
				<Route
					path='/:lang/section/:section_id'
					element={
						<MainTemplatePage>
							<SectionPage />
						</MainTemplatePage>
					}
				/>

				{/* About Page */}
				<Route
					path='/:lang/about-us'
					element={
						<MainTemplatePage>
							<AboutPage />
						</MainTemplatePage>
					}
				/>

				{/* Contact Page */}
				<Route
					path='/:lang/contact-us'
					element={
						<MainTemplatePage>
							<ContactPage />
						</MainTemplatePage>
					}
				/>

				{/* Category Details Page */}
				<Route
					path='/:lang/section/:section_id/category/:category_id'
					element={
						<MainTemplatePage>
							<CategoryDetailsPage />
						</MainTemplatePage>
					}
				/>

				{/* Course Details Page */}
				<Route
					path='/:lang/section/:section_id/course/:course_id'
					element={
						<MainTemplatePage>
							<CourseDetailsPage />
						</MainTemplatePage>
					}
				/>

				{/* Search Result Page */}
				<Route
					path='/:lang/search'
					element={
						<MainTemplatePage>
							<SearchResultPage />
						</MainTemplatePage>
					}
				/>

				{/* Login Page */}
				<Route
					path='/:lang/login'
					element={
						<MainTemplatePage>
							<SignInPage />
						</MainTemplatePage>
					}
				/>

				{/* SignUp Page */}
				<Route
					path='/:lang/signup'
					element={
						<MainTemplatePage>
							<SignUpPage />
						</MainTemplatePage>
					}
				/>

				{/* Not Found Page: Error 404 */}
				<Route path='/:lang/*' element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;

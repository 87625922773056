import React, { useEffect } from 'react';
import { Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import {
	FaFacebookF,
	FaGlobe,
	FaInstagram,
	FaLinkedinIn,
	FaMobileAlt,
	FaTwitter,
	FaYoutube,
} from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { fetchSections } from '../actions/sections.action';
import { fetchSettingsData } from '../actions/settingsData.action';
import { checkURL } from '../helpers/checkURL';
import { constructURL } from '../helpers/constructURL';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import '../styles/FooterComponent.styles.css';

// Components
import IconTextComponent from './IconTextComponent';
import MetaTagsComponent from './MetaTagsComponent';

const FooterComponent = ({ locale }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	const dispatch = useDispatch();

	const { sections, isSectionsLoading } = useSelector(
		(state) => state.sections
	);
	const { settings, contacts, isSettingsLoading } = useSelector(
		(state) => state.settingsData
	);

	useEffect(() => {
		dispatch(fetchSections(constructURL('sections'), locale ? 'en' : 'ar'));
		dispatch(fetchSettingsData(constructURL('settings'), locale ? 'en' : 'ar'));
	}, [dispatch, locale]);

	return (
		<>
			<MetaTagsComponent />

			{/* Footer Details */}
			{!isSettingsLoading && (
				<>
					{/* Toast Messages */}
					<ToastContainer
						position='top-right'
						autoClose={10000}
						newestOnTop
						pauseOnHover
						rtl={locale ? false : true}
					/>

					<Container
						dir={locale ? 'ltr' : 'rtl'}
						lang={locale ? 'en' : 'ar'}
						as='footer'
						id='footer'
						fluid='xs'
						className='py-4'
						style={{
							paddingRight: '1.2rem',
							paddingLeft: '1.2rem',
						}}
					>
						<Container
							fluid='md'
							className='position-relative text-white'
							style={{
								zIndex: '10',
							}}
						>
							{!isSettingsLoading && (
								<Row xs={1} className='justify-content-center g-4'>
									<Col>
										<Row className='g-4'>
											{/* Company Address */}
											<Col
												xs={12}
												sm={6}
												md={8}
												lg={4}
												xl={4}
												xxl={4}
												className='overflow-hidden'
											>
												<Row xs={1} className='g-3'>
													<Col
														className='display-6 fw-bold text-capitalize'
														style={{
															fontSize: '22px',
														}}
													>
														{settings.website_title}
													</Col>
													<Col>{settings.footer.address}</Col>
													<Col
														dangerouslySetInnerHTML={{
															__html: settings.footer.description,
														}}
													></Col>
												</Row>
											</Col>

											{/* Sections */}
											<Col
												xs={6}
												sm={6}
												md={4}
												lg={3}
												xl={3}
												xxl={2}
												className='overflow-hidden'
											>
												<Row xs={1} className='g-3'>
													<Col
														className='fw-bold text-capitalize'
														style={{
															fontSize: '18px',
														}}
													>
														{t('footer.headers.sections', { ns: 'words' })}
													</Col>
													<Col>
														<ul>
															{!isSectionsLoading &&
																sections.map((section) => (
																	<Link
																		key={section.title}
																		to={`/${lang}/section/${section.id}`}
																		target='_top'
																		className='text-capitalize text-white'
																		style={{
																			textDecoration: 'none',
																		}}
																		onClick={() =>
																			window.scrollTo({
																				top: 0,
																				left: 0,
																				behavior: 'smooth',
																			})
																		}
																	>
																		<li>{section.title}</li>
																	</Link>
																))}
														</ul>
													</Col>
												</Row>
											</Col>

											{/* Links */}
											<Col
												xs={6}
												sm={6}
												// xs={{ span: 5, order: 'last' }}
												md={4}
												lg={2}
												className='overflow-hidden'
											>
												<Row xs={1} className='g-3'>
													<Col
														className='fw-bold text-capitalize'
														style={{
															fontSize: '18px',
														}}
													>
														{t('footer.headers.links', { ns: 'words' })}
													</Col>
													<Col>
														<ul>
															<Link
																to={`/${lang}/about-us`}
																target='_top'
																className='text-capitalize'
																style={{
																	textDecoration: 'none',
																}}
																onClick={() =>
																	window.scrollTo({
																		top: 0,
																		left: 0,
																		behavior: 'smooth',
																	})
																}
															>
																<li>{t('footer.aboutUs', { ns: 'words' })}</li>
															</Link>

															<Link
																to={`/${lang}/contact-us`}
																target='_top'
																className='text-capitalize'
																style={{
																	textDecoration: 'none',
																}}
																onClick={() =>
																	window.scrollTo({
																		top: 0,
																		left: 0,
																		behavior: 'smooth',
																	})
																}
															>
																<li>
																	{t('footer.contactUs', { ns: 'words' })}
																</li>
															</Link>
														</ul>
													</Col>
												</Row>
											</Col>

											{/* Contacts */}
											<Col
												xs={12}
												sm={6}
												md={8}
												lg={{ span: 3, order: 'last' }}
												xl={3}
												xxl={4}
												className='overflow-hidden'
											>
												<Row xs={1} className='g-3'>
													<Col
														className='fw-bold text-capitalize'
														style={{
															fontSize: '18px',
														}}
													>
														{t('footer.headers.contactUs', { ns: 'words' })}
													</Col>
													<Col>
														<Row xs={1} md={2} lg={1} xxl={2} className='g-1'>
															{/* Telephone Numbers */}
															<Col>
																{contacts.mobiles && (
																	<IconTextComponent
																		icon={<FaMobileAlt size={24} />}
																		text={
																			<ul className='py-0'>
																				{contacts.mobiles.map(
																					(mobile, index) => (
																						<a
																							key={`${index}-${mobile.value}`}
																							href={`tel:${mobile.value}`}
																						>
																							<div className='phone-number'>
																								{mobile.value}
																							</div>
																						</a>
																					)
																				)}
																			</ul>
																		}
																		isCentered={false}
																	/>
																)}
																{contacts.telephones && (
																	<IconTextComponent
																		icon={<FiPhoneCall size={24} />}
																		text={
																			<ul className='py-0'>
																				{contacts.telephones.map(
																					(telephone, index) => (
																						<a
																							key={`${index}-${telephone.value}`}
																							href={`tel:${telephone.value}`}
																						>
																							<div className='phone-number'>
																								{telephone.value}
																							</div>
																						</a>
																					)
																				)}
																			</ul>
																		}
																		isCentered={false}
																	/>
																)}
															</Col>

															{/* Emails */}
															<Col
																xs={{ order: 'first' }}
																md={{ order: 'last' }}
																lg={{ order: 'first' }}
																xxl={{ order: 'last' }}
															>
																{contacts.emails &&
																	contacts.emails.map((email, index) => (
																		<a
																			key={`${index}-${email.value}`}
																			href={`mailto:${email.value}`}
																		>
																			<IconTextComponent
																				icon={<HiOutlineMail size={24} />}
																				text={email.value}
																				isCapitalized={false}
																			/>
																		</a>
																	))}
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>

									<Col>
										<Row xs={1} md={2} lg={3} className='g-2'>
											{/* Company Logo */}
											<Col
												lg={3}
												className={`d-flex ${
													window.innerWidth < 768
														? 'justify-content-center'
														: ''
												}`}
											>
												<Link
													to={`/${lang}`}
													target='_top'
													style={{
														textDecoration: 'none',
													}}
													onClick={() =>
														window.scrollTo({
															top: 0,
															left: 0,
															behavior: 'smooth',
														})
													}
												>
													<Image
														src={settings.images.footer}
														alt='Company Logo'
														style={{
															maxHeight:
																window.innerWidth < 768 ? '6rem' : '4rem',
														}}
													/>
												</Link>
											</Col>

											<Col lg={9}>
												<Row xs={1} lg={2} className='g-2 h-100'>
													{/* Social Media Accounts */}
													<Col
														as={ListGroup}
														horizontal
														className={`social-accounts d-flex align-items-center ${
															window.innerWidth < 768
																? 'justify-content-center'
																: 'justify-content-end'
														} px-0`}
													>
														{contacts.socials.map((social, index) => (
															<ListGroup.Item
																key={index}
																as='a'
																href={checkURL(social.value)}
																target='_blank'
																style={{
																	'--icon-color': social.value.includes(
																		'facebook'
																	)
																		? '#1877f2'
																		: social.value.includes('twitter')
																		? '#1da1f2'
																		: social.value.includes('linkedin')
																		? '#0077b5'
																		: social.value.includes('instagram')
																		? '#f56040'
																		: social.value.includes('youtube')
																		? '#ff0000'
																		: '#34bf49',
																}}
															>
																{social.value.includes('facebook') ? (
																	<FaFacebookF size={20} />
																) : social.value.includes('twitter') ? (
																	<FaTwitter size={20} />
																) : social.value.includes('linkedin') ? (
																	<FaLinkedinIn size={20} />
																) : social.value.includes('instagram') ? (
																	<FaInstagram size={20} />
																) : social.value.includes('youtube') ? (
																	<FaYoutube size={20} />
																) : (
																	<FaGlobe size={20} />
																)}
															</ListGroup.Item>
														))}
													</Col>

													{/* Copyrights */}
													<Col
														className={`display-6 d-flex align-items-center ${
															window.innerWidth < 768
																? 'justify-content-center'
																: 'justify-content-start'
														}`}
														style={{
															fontSize: locale ? '0.8rem' : '0.8rem',
														}}
													>
														{settings.footer.copyrights}
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							)}
						</Container>
					</Container>

					{/* Company Signature */}
					<Container
						dir={locale ? 'ltr' : 'rtl'}
						lang={locale ? 'en' : 'ar'}
						fluid
						className='company-signature'
					>
						<Row>
							<Col className='p-3 py-2 m-0 bg-dark text-white text-center align-middle'>
								<p
									className='m-0 fw-lighter text-capitalize'
									style={{
										fontSize: locale ? '0.8rem' : '0.8rem',
									}}
								>
									{t('copyrights.developed', { ns: 'words' })}
									<a
										href='https://marwan.tech/ar/service-request'
										target='_blank'
										rel='noreferrer'
										className='fw-light'
									>
										{t('copyrights.company', { ns: 'words' })}
									</a>
								</p>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</>
	);
};

export default FooterComponent;

import { useState } from 'react';

const useUserData = () => {
	const getUserData = () => {
		const userDataString = localStorage.getItem('user');

		return userDataString ? JSON.parse(userDataString) : null;
	};

	const saveUserData = (userData) => {
		localStorage.setItem('user', JSON.stringify(userData));

		setUserData(userData);
	};

	const [userData, setUserData] = useState(getUserData());

	return {
		setUserData: saveUserData,
		userData,
	};
};

export default useUserData;

import React, {
	Children,
	cloneElement,
	isValidElement,
	useEffect,
	useState,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FooterComponent from './../components/FooterComponent';
import NavbarComponent from './../components/NavbarComponent';

const MainTemplatePage = ({ children }) => {
	const { lang } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	const [locale, setLocale] = useState(lang === 'en' ? true : false);

	const handleLocale = () => {
		setLocale(!locale);

		setTimeout(() => {
			window.location.reload();
		}, 50);
	};

	useEffect(() => {
		navigate(
			`${location.pathname.replace(`/${lang}`, locale ? '/en' : '/ar')}`
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	return (
		<>
			{/* Page Navbar */}
			<NavbarComponent
				locale={locale}
				handleLocale={handleLocale}
				language={lang}
			/>

			{/* Current Page Content */}
			{Children.map(children, (child) =>
				isValidElement(child) ? cloneElement(child, { locale }) : child
			)}

			{/* Page Footer */}
			<FooterComponent locale={locale} />
		</>
	);
};

export default MainTemplatePage;

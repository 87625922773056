import { MONTH_NAMES } from '../constants/months.constant';
import { monthsSlice } from '../reducers/months.reducer';

// eslint-disable-next-line
const { monthsSuccess, startLoading, hasError } = monthsSlice.actions;

// Actions
export const fetchMonths =
	(language = 'en') =>
	async (dispatch) => {
		dispatch(startLoading());

		dispatch(
			monthsSuccess(language === 'ar' ? MONTH_NAMES.AR : MONTH_NAMES.EN)
		);
	};

import React from 'react';
import { Card, Image } from 'react-bootstrap';
import { FaHandHoldingHeart, FaPlay } from 'react-icons/fa';
import { trimEmptyTags } from '../helpers/trimEmptyTags';

// Styles
import '../styles/TestimonialsCardComponent.styles.css';

const TestimonialsCardComponent = ({ testimonial, locale }) => {
	return (
		<Card
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			className='testimonial-card'
		>
			<Card.Body className='p-0'>
				<Image
					src={require('../assets/images/quote.png')}
					alt='Testimonial Thumbnail'
					style={{
						width: '24px',
						height: '24px',
					}}
				/>
				<Card.Title
					className='text-limit testimonial-title text-capitalize my-3'
					style={{
						'--lines': 1,
						fontSize: '18px',
						fontWeight: '500',
					}}
					dangerouslySetInnerHTML={{ __html: trimEmptyTags(testimonial.title) }}
				></Card.Title>
				<Card.Text
					className='text-limit text-break testimonial-paragraph'
					style={{
						'--lines': 3,
					}}
					dangerouslySetInnerHTML={{
						__html: trimEmptyTags(testimonial.description),
					}}
				></Card.Text>
				<Card.Text
					className='text-limit text-break testimonial-paragraph pt-4 d-flex align-items-center'
					style={{
						'--lines': 1,
					}}
				>
					{`- ${testimonial.author}`}
				</Card.Text>

				<Card.Text
					className='position-absolute d-flex justify-content-center align-items-center'
					style={{
						backgroundColor: testimonial.has_video ? '#0071dc' : '#c8c7c7',
						width: '42px',
						height: '42px',
						borderRadius: '50%',
						right: locale ? '20px' : 'unset',
						left: locale ? 'unset' : '20px',
						bottom: '10px',
					}}
				>
					{testimonial.has_video ? (
						<a
							href={testimonial.video}
							target='_blank'
							rel='noopener noreferrer'
						>
							<FaPlay size={20} color='#ffffff' />
						</a>
					) : (
						<FaHandHoldingHeart size={20} color='#11142d' />
					)}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};

export default TestimonialsCardComponent;

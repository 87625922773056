import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import {
	Col,
	Container,
	FormGroup,
	FormLabel,
	Row,
	Spinner,
} from 'react-bootstrap';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import { constructURL } from '../helpers/constructURL';
import useUserData from '../hooks/useUserData';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import '../styles/SignInPage.styles.css';

// Components
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import LoadingPageComponent from '../components/LoadingPageComponent';
import ButtonComponent from './../components/ButtonComponent';

const SignInPage = ({ locale }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	const navigate = useNavigate();
	const { setUserData } = useUserData();

	const emailRef = useRef();
	const passwordRef = useRef();

	const breadcrumbItems = [
		{
			title: t('breadcrumb.home', { ns: 'words' }),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('breadcrumb.login', { ns: 'words' }),
			href: '',
			isActive: true,
		},
	];

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email(t('email.format', { ns: 'validations' }))
			.required(t('email.required', { ns: 'validations' })),

		password: Yup.string()
			.min(6, t('password.min', { ns: 'validations', min: 6 }))
			.required(t('password.required', { ns: 'validations' })),
	});

	const [isLoading, setIsLoading] = useState(true);
	const [isPasswordShown, setIsPasswordShown] = useState(false);

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'password':
				passwordRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 500:
				toast.error(t('errors.default', { ns: 'sentences' }));
				break;
			default:
				toast.error(message);
				break;
		}
	};

	const submitLoginForm = async (values, setSubmitting, language = 'en') => {
		axios({
			method: 'post',
			url: constructURL('login'),
			data: {
				email: values.email,
				password: values.password,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				setUserData(response.data.data);
				navigate(sessionStorage.getItem('beforeLogin') ?? `/${lang}`);
				window.location.reload();
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);

				// display appropriate error message
				typeof error.response.data.data === typeof {}
					? Object.keys(error.response.data.data).forEach((key) => {
							displayErrors(key);
							displayToast(
								error.response.status,
								error.response.data.data[key][0]
							);
					  })
					: displayToast(error.response.status, error.response.data.data);
			});
	};

	useEffect(() => {
		setIsLoading(true);
		setTimeout(() => setIsLoading(false), 500);
	}, [locale]);

	return (
		<section
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			id='signin-form'
			style={{
				minHeight: '100vh',
				padding: '5rem 0',
			}}
		>
			{isLoading ? (
				<LoadingPageComponent />
			) : (
				<>
					{/* Toast Messages */}
					<ToastContainer
						position='top-right'
						autoClose={10000}
						newestOnTop
						pauseOnHover
						rtl={locale ? false : true}
					/>

					{/* Breadcrumb */}
					<BreadcrumbComponent
						title={t('breadcrumb.login', { ns: 'words' })}
						items={breadcrumbItems}
					/>

					<Container
						fluid='sm'
						style={{
							maxWidth: '500px',
						}}
					>
						<Row xs={1} className='g-4'>
							{/* Page Form Container */}
							<Col
								className='p-4 overflow-hidden animate__animated animate__fadeInTop animate__delay-1s'
								style={{
									backgroundColor: '#f9fafd',
									border: '1px solid #e5f1fb',
									borderRadius: '0.375rem',
									'--animate-delay': '0.5s',
								}}
							>
								<Row xs={1} className='g-4'>
									<Col>
										<Formik
											initialValues={{
												email: '',
												password: '',
											}}
											validationSchema={LoginSchema}
											onSubmit={(values, { setSubmitting, resetForm }) => {
												setSubmitting(true);
												submitLoginForm(
													values,
													setSubmitting,
													lang === 'en' ? 'en' : 'ar'
												);
											}}
										>
											{({
												values,
												errors,
												touched,
												handleChange,
												handleBlur,
												handleSubmit,
												isSubmitting,
											}) => (
												<Form
													onSubmit={(event) => {
														event.preventDefault();
														handleSubmit();
													}}
												>
													{/* Email */}
													<FormGroup
														as={Col}
														className={`mb-3 animate__animated ${
															locale
																? 'animate__fadeInRight'
																: 'animate__fadeInLeft'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '0.5s',
														}}
													>
														<FormLabel
															htmlFor='email'
															className='text-capitalize'
														>
															{t('labels.email', { ns: 'words' })}
														</FormLabel>
														<Field
															id='email'
															type='email'
															innerRef={emailRef}
															placeholder='mail@domain.com'
															autoComplete='off'
															name='email'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.email}
															className={`form-control ${
																touched.email && errors.email
																	? 'is-invalid'
																	: ''
															}`}
														/>
														<ErrorMessage
															component='div'
															name='email'
															className='invalid-feedback'
														/>
													</FormGroup>

													{/* Password */}
													<FormGroup
														as={Col}
														className={`position-relative mb-3 animate__animated ${
															locale
																? 'animate__fadeInLeft'
																: 'animate__fadeInRight'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '0.75s',
														}}
													>
														<FormLabel
															htmlFor='password'
															className='text-capitalize'
														>
															{t('labels.password', { ns: 'words' })}
														</FormLabel>
														<Field
															id='password'
															type={isPasswordShown ? 'text' : 'password'}
															innerRef={passwordRef}
															placeholder={t('placeholders.password', {
																ns: 'words',
															})}
															autoComplete='off'
															name='password'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.password}
															className={`form-control ${
																touched.password && errors.password
																	? 'is-invalid'
																	: ''
															}`}
														/>
														<div
															className='show-password text-muted position-absolute'
															onClick={() =>
																setIsPasswordShown(!isPasswordShown)
															}
														>
															{isPasswordShown ? (
																<FaRegEye size={26} />
															) : (
																<FaRegEyeSlash size={26} />
															)}
														</div>
														<ErrorMessage
															component='div'
															name='password'
															className='invalid-feedback'
														/>
													</FormGroup>

													{/* Submit Form */}
													<FormGroup
														className='d-flex justify-content-center mt-3 animate__animated animate__zoomIn animate__delay-1s mt-5 mb-3'
														style={{
															'--animate-delay': '1.25s',
														}}
													>
														<ButtonComponent
															title={
																isSubmitting
																	? t('buttons.loading', { ns: 'words' })
																	: t('buttons.login', { ns: 'words' })
															}
															icon={
																isSubmitting ? (
																	<Spinner
																		animation='grow'
																		variant='light'
																		size='sm'
																		className={`${locale ? 'me-2' : 'ms-2'}`}
																	/>
																) : (
																	<></>
																)
															}
															type='submit'
															styles={{
																button: {
																	width: '100%',
																	'--bs-btn-padding-x': '30px',
																	'--bs-btn-padding-y': '20px',
																},
															}}
															disabled={isSubmitting ? true : false}
														/>
													</FormGroup>

													{/* Signup Link */}
													<FormGroup
														className='d-flex justify-content-center align-items-center mt-4 animate__animated animate__zoomIn animate__delay-1s'
														style={{
															'--animate-delay': '1.5s',
														}}
													>
														<span
															className={`text-capitalize ${
																locale ? 'me-2' : 'ms-2'
															}`}
															style={{
																color: '#808191',
															}}
														>
															{t('doNotHaveAccount', { ns: 'sentences' })}
														</span>
														<Link
															to={`/${lang}/signup`}
															target='_top'
															className='other-option fw-bold'
															style={{
																textDecoration: 'none',
																textTransform: 'capitalize',
															}}
														>
															{t('signup', { ns: 'words' })}
														</Link>
													</FormGroup>
												</Form>
											)}
										</Formik>
									</Col>
								</Row>
							</Col>
						</Row>
					</Container>
				</>
			)}
		</section>
	);
};

export default SignInPage;

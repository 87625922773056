import { createSlice } from '@reduxjs/toolkit';

// Slice

export const certificatesSlice = createSlice({
	name: 'certificates',
	initialState: {
		certificates: [],
		isCertificatesLoading: true,
	},
	reducers: {
		certificatesSuccess: (state, action) => {
			state.certificates = action.payload;
			state.isCertificatesLoading = false;
		},

		startLoading: (state) => {
			state.isCertificatesLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isCertificatesLoading = false;
		},
	},
});

export default certificatesSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

// Slice

export const testimonialsSlice = createSlice({
	name: 'testimonials',
	initialState: {
		testimonials: [],
		isTestimonialsLoading: true,
	},
	reducers: {
		testimonialsSuccess: (state, action) => {
			state.testimonials = action.payload;
			state.isTestimonialsLoading = false;
		},

		startLoading: (state) => {
			state.isTestimonialsLoading = true;
		},

		hasError: (state, action) => {
			state.error = action.payload;
			state.isTestimonialsLoading = false;
		},
	},
});

export default testimonialsSlice.reducer;

import { ErrorMessage, Field, Form, Formik } from 'formik';
import 'moment/locale/ar';
import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react';
import { Fade, Flip, Zoom } from 'react-awesome-reveal';
import {
	Col,
	Container,
	Image,
	InputGroup,
	OverlayTrigger,
	Row,
	Spinner,
	Tooltip,
} from 'react-bootstrap';
import { FaAngleDoubleUp, FaHeart, FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as Yup from 'yup';
import { fetchCategories } from '../actions/categories.action';
import { fetchCertificates } from '../actions/certificates.action';
import { fetchFeatures } from '../actions/features.action';
import { fetchHomeData } from '../actions/homeData.action';
import { fetchTestimonials } from '../actions/testimonials.action';
import ButtonComponent from '../components/ButtonComponent';
import CategoryCardComponent from '../components/CategoryCardComponent';
import CourseCardComponent from '../components/CourseCardComponent';
import LoadingPageComponent from '../components/LoadingPageComponent';
import TestimonialsCardComponent from '../components/TestimonialsCardComponent';
import { constructURL } from '../helpers/constructURL';
import { trimEmptyTags } from '../helpers/trimEmptyTags';
import FeatureCardComponent from './../components/FeatureCardComponent';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/HomePage.styles.css';

const HomePage = ({ locale }) => {
	locale ? moment.locale('en') : moment.locale('ar');

	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
	}, [lang]);

	const navigate = useNavigate();

	const dispatch = useDispatch();

	const courseTitleRef = useRef();

	const { homeData, trendingCourses, mostEnrolledCourses, isHomeDataLoading } =
		useSelector((state) => state.homeData);
	const { categories, isCategoriesLoading } = useSelector(
		(state) => state.categories
	);
	const { certificates, isCertificatesLoading } = useSelector(
		(state) => state.certificates
	);
	const { testimonials, isTestimonialsLoading } = useSelector(
		(state) => state.testimonials
	);
	const { features, isFeaturesLoading } = useSelector(
		(state) => state.features
	);

	const [filteredCourses, setFilteredCourses] = useState(null);
	const [showScrollToTop, setShowScrollToTop] = useState(false);

	useEffect(() => {
		dispatch(fetchHomeData(constructURL('home'), locale ? 'en' : 'ar'));
		dispatch(
			fetchCertificates(constructURL('certificates'), locale ? 'en' : 'ar')
		);
		dispatch(fetchCategories(constructURL('categories'), locale ? 'en' : 'ar'));
		dispatch(
			fetchTestimonials(constructURL('testimonials'), locale ? 'en' : 'ar')
		);
		dispatch(fetchFeatures(constructURL('features'), locale ? 'en' : 'ar'));

		sessionStorage.setItem(
			'searchTerms',
			JSON.stringify({
				title: '',
				section_id: '',
				category_id: '',
				city_id: '',
				month: '',
				year: '',
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	useEffect(() => {
		setFilteredCourses(trendingCourses);
	}, [isHomeDataLoading, trendingCourses]);

	window.addEventListener('scroll', () => {
		window.scrollY > 400 ? setShowScrollToTop(true) : setShowScrollToTop(false);
	});

	const searchSchema = Yup.object().shape({});

	const submitSearchForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		sessionStorage.setItem('searchTerms', JSON.stringify(values));
		navigate(`/${lang}/search`);
		window.location.reload();
	};

	return (
		<>
			{isHomeDataLoading ||
			isCertificatesLoading ||
			isCategoriesLoading ||
			isFeaturesLoading ||
			isTestimonialsLoading ? (
				<LoadingPageComponent />
			) : (
				<section
					dir={locale ? 'ltr' : 'rtl'}
					lang={locale ? 'en' : 'ar'}
					id='home'
					style={{
						minHeight: '100vh',
						padding: '6rem 0 0',
					}}
				>
					{/* Toast Messages */}
					<ToastContainer
						position='top-right'
						autoClose={10000}
						newestOnTop
						pauseOnHover
						rtl={locale ? false : true}
					/>

					{/* Scroll To Top Button */}
					<OverlayTrigger
						placement='top'
						overlay={
							<Tooltip className='text-capitalize'>
								{t('goTop', { ns: 'words' })}
							</Tooltip>
						}
					>
						<div
							className='back-to-top '
							style={{
								display: showScrollToTop ? 'flex' : 'none',
							}}
							onClick={() =>
								window.scrollTo({
									top: 0,
									left: 0,
									behavior: 'smooth',
								})
							}
						>
							<FaAngleDoubleUp />
						</div>
					</OverlayTrigger>

					{/* Hero Section */}
					<Container
						fluid
						className='banner-area'
						style={{
							backgroundColor: '#e5f1fb',
							paddingTop: '6rem',
						}}
					>
						<Container
							fluid='md'
							className='position-relative overflow-hidden d-flex'
							style={{
								minHeight: '75vh',
							}}
						>
							<Row
								xs={1}
								lg={2}
								className='g-4'
								style={{
									height: 'inherit',
								}}
							>
								<Zoom delay={50}>
									<Col className='d-flex flex-column justify-content-center align-items-start'>
										{/* Home Banner Title */}
										<h1 className='banner-title m-0 p-0'>
											{homeData.pages.home_banner.title}
										</h1>

										{/* Home Banner Description */}
										<div
											dangerouslySetInnerHTML={{
												__html: trimEmptyTags(
													homeData.pages.home_banner.description
												),
											}}
											className='banner-paragraph p-0 mb-0 mt-4'
										></div>

										{/* Search Now */}
										<Formik
											initialValues={{
												title: '',
											}}
											validationSchema={searchSchema}
											onSubmit={(values, { setSubmitting, resetForm }) => {
												setSubmitting(true);
												submitSearchForm(
													values,
													setSubmitting,
													resetForm,
													lang === 'en' ? 'en' : 'ar'
												);
											}}
										>
											{({
												values,
												errors,
												touched,
												handleChange,
												handleBlur,
												handleSubmit,
												isSubmitting,
												setFieldValue,
											}) => (
												<>
													{/* Search Form */}
													<Form
														onSubmit={(event) => {
															event.preventDefault();
															handleSubmit();
														}}
														className='overflow-hidden w-100'
													>
														<Row xs={1} className='g-4'>
															{/* Title Filter */}
															<InputGroup className='my-4'>
																<Field
																	id='course_title'
																	type='text'
																	innerRef={courseTitleRef}
																	placeholder={t('placeholders.courseTitle', {
																		ns: 'words',
																	})}
																	autoComplete='off'
																	name='title'
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={values.title}
																	className={`form-control text-capitalize py-3 rounded-0 ${
																		touched.title && errors.title
																			? 'is-invalid'
																			: ''
																	}`}
																/>

																{/* Submit Form */}
																<ButtonComponent
																	title={t('buttons.search', { ns: 'words' })}
																	icon={
																		isSubmitting ? (
																			<Spinner
																				animation='grow'
																				variant='light'
																				size='sm'
																				className={`${
																					locale ? 'me-2' : 'ms-2'
																				}`}
																			/>
																		) : (
																			<FaSearch
																				className={`position-relative ${
																					locale ? 'me-2' : 'ms-2'
																				}`}
																				style={{
																					zIndex: '10',
																				}}
																			/>
																		)
																	}
																	className='rounded-0'
																	disabled={isSubmitting ? true : false}
																/>

																<ErrorMessage
																	component='div'
																	name='title'
																	className='invalid-feedback'
																/>
															</InputGroup>
														</Row>
													</Form>
												</>
											)}
										</Formik>

										{/* Ratings */}
										<div className='ratings my-3 d-flex flex-wrap align-items-center'>
											<Image
												src={require('../assets/images/reviewers.png')}
												alt='Reviewers Preview'
											/>
											<span
												className={`mt-2 word-nowrap text-capitalize ${
													locale ? 'ms-2' : 'me-2'
												}`}
											>
												{t('homeBanner.ratings', { ns: 'sentences' })}
											</span>
										</div>

										{/* Check Reviews */}
										<div className='check-reviews d-flex flex-wrap align-items-center'>
											<span
												className={`text-capitalize ${
													locale ? 'me-2' : 'ms-2'
												}`}
											>
												{t('homeBanner.customers', { ns: 'sentences' })}
											</span>
											<span> </span>
											<span className='link-hover word-nowrap text-capitalize'>
												<Link
													to={`/${lang}/contact-us`}
													target='_top'
													style={{
														textDecoration: 'none',
													}}
												>
													{t('homeBanner.reviews', { ns: 'sentences' })}
												</Link>
											</span>
										</div>
									</Col>
								</Zoom>

								<Col className='d-flex justify-content-center align-items-end'>
									<Fade delay={50} direction='up'>
										<Image
											fluid
											src={homeData.pages.home_banner.image}
											className='w-100'
											alt='Home Banner Preview'
										/>
									</Fade>
								</Col>
							</Row>
						</Container>
					</Container>

					{/* Certificates */}
					<Container
						fluid='md'
						className='certificates overflow-hidden'
						style={{
							paddingRight: '1.2rem',
							paddingLeft: '1.2rem',
							borderBottom: '1px solid #e5f1fb',
						}}
					>
						<Fade delay={50} direction='down'>
							<Swiper
								className='overflow-hidden'
								dir={locale ? 'ltr' : 'rtl'}
								slidesPerView={1}
								spaceBetween={10}
								breakpoints={{
									576: {
										slidesPerView: 2,
										spaceBetween: 20,
									},
									992: {
										slidesPerView: 6,
										spaceBetween: 20,
									},
								}}
								autoplay={{
									delay: 1000,
									disableOnInteraction: false,
									pauseOnMouseEnter: true,
								}}
								grabCursor={true}
								loop={true}
								modules={[Autoplay]}
							>
								{certificates.map((certificate, index) => (
									<SwiperSlide
										key={index}
										className='d-flex justify-content-center align-items-center'
									>
										<Image
											src={certificate.image}
											alt='Certificate Thumbnail'
											className='certificate-image img-fluid w-100'
											style={{
												height: '10rem',
												objectFit: 'contain',
												objectPosition: 'center',
											}}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</Fade>
					</Container>

					{/* Categories Carousel */}
					<Container
						className='position-relative overflow-hidden'
						style={{
							padding: '7.5rem 1.2rem 0',
						}}
					>
						<Row className='d-flex flex-column justify-content-center align-items-center'>
							<Col
								lg={7}
								className='text-center d-flex flex-column justify-content-center'
								style={{
									color: '#11142d',
									marginBottom: '45px',
								}}
							>
								<Zoom delay={50}>
									{/* Section Title */}
									<h2
										className='mb-4'
										style={{
											fontSize: window.innerWidth < 768 ? '30px' : '40px',
											fontWeight: '500',
										}}
									>
										{homeData.pages.categories.title}
									</h2>

									{/* Section Description */}
									<p
										style={{
											color: '#808191',
										}}
									>
										{homeData.pages.categories.description}
									</p>
								</Zoom>
							</Col>
						</Row>

						<Fade delay={50} direction='down' className='overflow-hidden'>
							{categories && (
								<Swiper
									dir={locale ? 'ltr' : 'rtl'}
									slidesPerView={1}
									spaceBetween={20}
									breakpoints={{
										768: {
											slidesPerView: 3,
											spaceBetween: 20,
										},
										1440: {
											slidesPerView: 5,
											spaceBetween: 20,
										},
									}}
									autoplay={{
										delay: 3000,
										disableOnInteraction: false,
										pauseOnMouseEnter: true,
									}}
									grabCursor={true}
									loop={true}
									modules={[Autoplay]}
								>
									{categories.map((category, index) => (
										<SwiperSlide
											key={index}
											style={{
												padding: '30px 0.5rem 25px',
												borderRadius: '12px',
											}}
										>
											<Link
												to={`/${lang}/section/${category.section_id}/category/${category.id}`}
												target='_top'
												style={{
													textDecoration: 'none',
												}}
											>
												<CategoryCardComponent
													category={category}
													locale={locale}
												/>
											</Link>
										</SwiperSlide>
									))}
								</Swiper>
							)}
						</Fade>
					</Container>

					{/* Most Trending Courses */}
					<Container
						fluid='md'
						className='overflow-hidden'
						style={{
							padding: '7.5rem 1.2rem 0',
						}}
					>
						<Fade delay={50} direction='up'>
							{/* Section Title */}
							<h2
								className={`text-capitalize mb-4 ${
									window.innerWidth < 768 ? 'text-center' : ''
								}`}
								style={{
									color: '#11142d',
									fontSize: window.innerWidth < 768 ? '30px' : '40px',
									marginBottom: '45px',
									fontWeight: '500',
								}}
							>
								{t('mostTrendingCourses', { ns: 'sentences' })}
							</h2>
						</Fade>
						<Fade delay={50} direction='up'>
							{/* Categories Filter */}
							<Swiper
								dir={locale ? 'ltr' : 'rtl'}
								slidesPerView={'auto'}
								spaceBetween={window.innerWidth < 992 ? 10 : 30}
								grabCursor={true}
								navigation={true}
								modules={[Navigation]}
								className='courses-filter overflow-hidden'
								style={{
									marginBottom: '30px',
									paddingRight: '55px',
									paddingLeft: '55px',
								}}
							>
								<SwiperSlide
									className='text-nowrap nav-link active text-capitalize'
									onClick={(event) => {
										setFilteredCourses(trendingCourses);

										document
											.querySelectorAll('.courses-filter .nav-link')
											.forEach((navLink) => {
												if (navLink.classList.contains('active')) {
													navLink.classList.remove('active');
												}
											});

										if (!event.target.classList.contains('active')) {
											event.target.classList.add('active');
										}
									}}
								>
									{t('allCourses', { ns: 'words' })}
								</SwiperSlide>

								{Array.from(
									new Set(
										trendingCourses.map((course) => course.category.title)
									)
								)
									.filter(
										(categoryTitle) =>
											categoryTitle !== '' &&
											categoryTitle !== null &&
											categoryTitle !== undefined
									)
									.map((categoryTitle, index) => {
										return (
											<SwiperSlide
												key={index}
												className='text-nowrap nav-link'
												onClick={(event) => {
													setFilteredCourses(null);
													setFilteredCourses(
														trendingCourses.filter(
															(course) =>
																course.category.title === categoryTitle
														)
													);

													document
														.querySelectorAll('.courses-filter .nav-link')
														.forEach((navLink) => {
															if (navLink.classList.contains('active')) {
																navLink.classList.remove('active');
															}
														});

													if (!event.target.classList.contains('active')) {
														event.target.classList.add('active');
													}
												}}
											>
												{categoryTitle}
											</SwiperSlide>
										);
									})}
							</Swiper>

							{/* Filtered Courses */}
							<Swiper
								dir={locale ? 'ltr' : 'rtl'}
								id='courses-filter-results'
								slidesPerView={1}
								spaceBetween={20}
								breakpoints={{
									576: {
										slidesPerView: 2,
										spaceBetween: 20,
									},
									991: {
										slidesPerView: 3,
										spaceBetween: 20,
									},
									1400: {
										slidesPerView: 4,
										spaceBetween: 25,
									},
								}}
								autoplay={{
									delay: 2000,
									disableOnInteraction: false,
									pauseOnMouseEnter: true,
								}}
								grabCursor={true}
								loop={true}
								navigation={true}
								pagination={{
									dynamicBullets: true,
								}}
								modules={[Autoplay, Navigation, Pagination]}
							>
								{filteredCourses
									.filter((course) => course.category !== null)
									.map((course, index) => (
										<SwiperSlide
											key={index}
											style={{
												paddingRight: '0.5rem',
												paddingLeft: '0.5rem',
											}}
										>
											<CourseCardComponent course={course} locale={locale} />
										</SwiperSlide>
									))}
							</Swiper>
						</Fade>
					</Container>

					{/* Features */}
					<Container
						fluid='md'
						style={{
							padding: '8.5rem 1.2rem 0',
						}}
					>
						<Row
							xs={1}
							className='g-1 d-flex flex-column justify-content-center align-items-center'
						>
							<Col
								lg={7}
								className='d-flex flex-column justify-content-center align-items-center'
							>
								<Zoom delay={50}>
									{/* Section Title */}
									<h2
										className='mb-4 text-capitalize text-center'
										style={{
											color: '#11142d',
											fontSize: window.innerWidth < 768 ? '30px' : '40px',
										}}
									>
										{homeData.pages.features.title}
									</h2>

									{/* Section Description */}
									<p
										className='text-center'
										style={{
											color: '#808191',
										}}
									>
										{homeData.pages.features.description}
									</p>
								</Zoom>
							</Col>
						</Row>

						<Row
							xs={1}
							md={2}
							xl={3}
							className='g-4 mt-2'
							style={{
								paddingRight: '0.5rem',
								paddingLeft: '0.5rem',
							}}
						>
							{features.map((feature, index) => (
								<Flip key={index} delay={index * 300} direction='vertical'>
									<Col>
										<FeatureCardComponent feature={feature} locale={locale} />
									</Col>
								</Flip>
							))}
						</Row>
					</Container>

					{/* Most Students Enroll */}
					<Container
						fluid='md'
						className='overflow-hidden'
						style={{
							padding: '6.5rem 1.2rem 0',
						}}
					>
						<Fade delay={50} direction='up'>
							{/* Section Title */}
							<h2
								className={`text-capitalize mb-4 ${
									window.innerWidth < 768 ? 'text-center' : ''
								}`}
								style={{
									color: '#11142d',
									fontSize: window.innerWidth < 768 ? '30px' : '40px',
									fontWeight: '500',
								}}
							>
								{t('mostEnrolledCourses', { ns: 'sentences' })}
							</h2>
						</Fade>
						<Row
							xs={1}
							sm={2}
							lg={3}
							xl={4}
							className='g-4 mt-2'
							style={{
								paddingRight: '0.5rem',
								paddingLeft: '0.5rem',
							}}
						>
							{mostEnrolledCourses
								.filter((course) => course.category !== null)
								.map((course, index) => (
									<Flip key={index} delay={index * 50} direction='vertical'>
										<Col>
											<CourseCardComponent course={course} locale={locale} />
										</Col>
									</Flip>
								))}
						</Row>
					</Container>

					{/* Testimonials */}
					<Container
						fluid='md'
						className='overflow-hidden'
						style={{
							padding: '8.5rem 1.2rem 8rem',
						}}
					>
						<Fade delay={50} direction='up'>
							{/* Section Title */}
							<h2
								className='text-capitalize mb-4'
								style={{
									color: '#11142d',
									width: '70%',
									fontSize: window.innerWidth < 768 ? '24px' : '40px',
									fontWeight: '500',
									whiteSpace: 'nowrap',
								}}
							>
								<span>{t('people', { ns: 'words' })}</span>
								<FaHeart
									className='mx-2 animate__animated animate__heartBeat animate__infinite'
									color='#e31b23'
									size={24}
								/>
								<span>{t('companyTitle', { ns: 'words' })}</span>
							</h2>
						</Fade>
						<Flip delay={50} direction='horizontal'>
							<Swiper
								dir={locale ? 'ltr' : 'rtl'}
								slidesPerView={1}
								spaceBetween={20}
								breakpoints={{
									576: {
										slidesPerView: 2,
										spaceBetween: 20,
									},
									992: {
										slidesPerView: 3,
										spaceBetween: 30,
									},
								}}
								autoplay={{
									delay: 2000,
									disableOnInteraction: false,
									pauseOnMouseEnter: true,
								}}
								grabCursor={true}
								loop={true}
								navigation={true}
								pagination={{
									dynamicBullets: true,
								}}
								modules={[Autoplay, Navigation, Pagination]}
							>
								{testimonials.map((testimonial, index) => (
									<SwiperSlide
										key={index}
										style={{
											paddingRight: '0.5rem',
											paddingLeft: '0.5rem',
										}}
									>
										<TestimonialsCardComponent
											testimonial={testimonial}
											locale={locale}
										/>
									</SwiperSlide>
								))}
							</Swiper>
						</Flip>
					</Container>
				</section>
			)}
		</>
	);
};

export default HomePage;

import React from 'react';
import { Container } from 'react-bootstrap';
import '../styles/LoadingPageComponent.styles.css';

const LoadingPageComponent = () => {
	return (
		<Container
			fluid
			id='spinner-container'
			className='d-flex justify-content-center align-items-center w-100'
		>
			<div className='spinner'></div>
		</Container>
	);
};

export default LoadingPageComponent;

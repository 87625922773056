import React from 'react';
import { Button } from 'react-bootstrap';

// Styles
import '../styles/ButtonComponent.styles.css';

const ButtonComponent = ({
	title = null,
	type = null,
	className = 'btn-base',
	icon = null,
	href = null,
	target = false,
	disabled = false,
	onClick = null,
	styles = {},
}) => {
	return (
		<Button
			type={type}
			as={href ? 'a' : 'button'}
			href={disabled ? '' : href ?? ''}
			target={disabled ? '_self' : target ? '_blank' : '_self'}
			onClick={onClick}
			className={`${className} ${
				disabled ? 'disabled' : ''
			} btn-base text-bg-dark text-capitalize d-flex justify-content-center align-items-center`}
			style={styles.button}
		>
			{/* Button Icon */}
			{icon && <>{icon}</>}

			{/* Button Text */}
			{title && (
				<span
					className='position-relative'
					style={{
						zIndex: '10',
						...styles.title,
					}}
				>
					{title}
				</span>
			)}
		</Button>
	);
};

export default ButtonComponent;

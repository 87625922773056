import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Flip } from 'react-awesome-reveal';
import {
	Col,
	Container,
	FormGroup,
	FormLabel,
	FormText,
	Row,
	Spinner,
} from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from 'yup';
import BreadcrumbComponent from '../components/BreadcrumbComponent';
import ButtonComponent from '../components/ButtonComponent';
import { constructURL } from '../helpers/constructURL';
import useUserData from '../hooks/useUserData';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import 'react-toastify/dist/ReactToastify.css';
import '../styles/ContactPage.styles.css';

// Components
import LoadingPageComponent from '../components/LoadingPageComponent';

const ContactPage = ({ locale }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	const { userData } = useUserData();

	const { isSectionsLoading } = useSelector((state) => state.sections);

	const firstNameRef = useRef();
	const lastNameRef = useRef();
	const emailRef = useRef();
	const phoneRef = useRef();
	const messageRef = useRef();

	const breadcrumbItems = [
		{
			title: t('breadcrumb.home', { ns: 'words' }),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('breadcrumb.contactUs', { ns: 'words' }),
			href: '',
			isActive: true,
		},
	];

	const contactSchema = Yup.object().shape({
		fname: Yup.string()
			.min(2, t('firstName.min', { ns: 'validations', min: 2 }))
			.max(100, t('firstName.max', { ns: 'validations', max: 100 }))
			.required(t('firstName.required', { ns: 'validations' })),

		lname: Yup.string()
			.min(2, t('lastName.min', { ns: 'validations', min: 2 }))
			.max(100, t('lastName.max', { ns: 'validations', max: 100 }))
			.required(t('lastName.required', { ns: 'validations' })),

		email: Yup.string()
			.email(t('email.format', { ns: 'validations' }))
			.required(t('email.required', { ns: 'validations' })),

		phone: Yup.string()
			.min(6, t('phone.min', { ns: 'validations', min: 6 }))
			.matches(/^[0-9+]+/, t('phone.format', { ns: 'validations' }))
			.required(t('phone.required', { ns: 'validations' })),

		message: Yup.string()
			.min(2, t('message.min', { ns: 'validations', min: 2 }))
			.max(500, t('message.max', { ns: 'validations', max: 500 }))
			.required(t('message.required', { ns: 'validations' })),
	});

	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				phoneRef.current.classList.add('is-invalid');
				break;

			case 'message':
				messageRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 400:
				toast.error(message);
				break;
			default:
				toast.error(t('errors.default', { ns: 'sentences' }));
				break;
		}
	};

	const submitContactForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'en'
	) => {
		console.log('values:', values);
		axios({
			method: 'post',
			url: constructURL('contact-us'),
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				phone: values.phone,
				message: values.message,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);

				displayToast(response.status, response.data.message);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);
				if (error.response.status === 401) {
					displayToast(error.response.status, error.response.data);
				} else if (error.response.data.data.length !== 0) {
					Object.keys(error.response.data.data).forEach((key) => {
						displayErrors(key);
						displayToast(
							error.response.status,
							error.response.data.data[key][0]
						);
					});
				} else {
					displayToast(error.response.status, error.response.data.message);
				}
			});
	};

	return (
		<section
			dir={locale ? 'ltr' : 'rtl'}
			lang={locale ? 'en' : 'ar'}
			id='contact-us'
			style={{
				minHeight: '100vh',
				padding: '5rem 0',
			}}
		>
			{isSectionsLoading ? (
				<LoadingPageComponent />
			) : (
				<>
					{/* Toast Messages */}
					<ToastContainer
						position='top-right'
						autoClose={10000}
						newestOnTop
						pauseOnHover
						rtl={locale ? false : true}
						limit={3}
					/>

					{/* Breadcrumb */}
					<BreadcrumbComponent
						title={t('breadcrumb.contactUs', { ns: 'words' })}
						items={breadcrumbItems}
					/>

					<Container fluid='md'>
						<Row xs={1} className='gx-5 gy-4'>
							{/* Contact Form */}
							<Flip delay={50} direction='vertical'>
								<Col className='d-flex justify-content-center align-items-center'>
									<Formik
										initialValues={{
											fname: userData ? userData.fname : '',
											lname: userData ? userData.lname : '',
											email: userData ? userData.email : '',
											phone: userData ? userData.phone : '',
											message: '',
										}}
										validationSchema={contactSchema}
										onSubmit={(values, { setSubmitting, resetForm }) => {
											setSubmitting(true);
											submitContactForm(
												values,
												setSubmitting,
												resetForm,
												lang === 'en' ? 'en' : 'ar'
											);
										}}
									>
										{({
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											handleSubmit,
											isSubmitting,
											setFieldValue,
										}) => (
											<Form
												onSubmit={(event) => {
													event.preventDefault();
													handleSubmit();
												}}
												className='p-4 overflow-hidden'
												style={{
													width: '40rem',
													backgroundColor: '#f9fafd',
													border: '1px solid #e5f1fb',
													borderRadius: '0.375rem',
												}}
											>
												<Row xs={1} sm={2}>
													{/* First Name */}
													<FormGroup
														as={Col}
														className={`mb-3 animate__animated ${
															locale
																? 'animate__fadeInLeft'
																: 'animate__fadeInRight'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '0.5s',
														}}
													>
														<FormLabel
															htmlFor='first_name'
															className='text-capitalize'
														>
															{t('labels.firstName', { ns: 'words' })}
														</FormLabel>
														<Field
															id='first_name'
															type='text'
															innerRef={firstNameRef}
															placeholder={t('placeholders.firstName', {
																ns: 'words',
															})}
															autoComplete='off'
															name='fname'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.fname}
															className={`form-control text-capitalize ${
																touched.fname && errors.fname
																	? 'is-invalid'
																	: ''
															}`}
														/>
														<ErrorMessage
															component='div'
															name='fname'
															className='invalid-feedback'
														/>
													</FormGroup>

													{/* Last Name */}
													<FormGroup
														as={Col}
														className={`mb-3 animate__animated ${
															locale
																? 'animate__fadeInRight'
																: 'animate__fadeInLeft'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '0.5s',
														}}
													>
														<FormLabel
															htmlFor='last_name'
															className='text-capitalize'
														>
															{t('labels.lastName', { ns: 'words' })}
														</FormLabel>
														<Field
															id='last_name'
															type='text'
															innerRef={lastNameRef}
															placeholder={t('placeholders.lastName', {
																ns: 'words',
															})}
															autoComplete='off'
															name='lname'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.lname}
															className={`form-control text-capitalize ${
																touched.lname && errors.lname
																	? 'is-invalid'
																	: ''
															}`}
														/>
														<ErrorMessage
															component='div'
															name='lname'
															className='invalid-feedback'
														/>
													</FormGroup>

													{/* Email */}
													<FormGroup
														as={Col}
														className={`mb-3 animate__animated ${
															locale
																? 'animate__fadeInLeft'
																: 'animate__fadeInRight'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '0.75s',
														}}
													>
														<FormLabel
															htmlFor='email'
															className='text-capitalize'
														>
															{t('labels.email', { ns: 'words' })}
														</FormLabel>
														<Field
															id='email'
															type='email'
															innerRef={emailRef}
															placeholder='mail@domain.com'
															autoComplete='off'
															name='email'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.email}
															className={`form-control ${
																touched.email && errors.email
																	? 'is-invalid'
																	: ''
															}`}
														/>
														<ErrorMessage
															component='div'
															name='email'
															className='invalid-feedback'
														/>
													</FormGroup>

													{/* Mobile Number */}
													<FormGroup
														as={Col}
														className={`mb-3 animate__animated ${
															locale
																? 'animate__fadeInRight'
																: 'animate__fadeInLeft'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '0.75s',
														}}
													>
														<FormLabel
															htmlFor='phone'
															className='text-capitalize'
														>
															{t('labels.phone', { ns: 'words' })}
														</FormLabel>
														<Field name='phone' innerRef={phoneRef}>
															{(field, form, meta) => (
																<>
																	<PhoneInput
																		{...field}
																		id='phone'
																		dir='ltr'
																		ref={phoneRef}
																		placeholder='56 123 0620'
																		defaultCountry='SA'
																		autoComplete='off'
																		onChange={(event) => {
																			setFieldValue('phone', event);
																		}}
																		onBlur={handleBlur}
																		value={values.phone}
																		className={`${
																			field.meta.touched && field.meta.error
																				? 'is-invalid'
																				: ''
																		}`}
																	/>
																	{field.meta.error && (
																		<div className='invalid-feedback'>
																			{field.meta.error}
																		</div>
																	)}
																</>
															)}
														</Field>
													</FormGroup>
												</Row>

												<Row xs={1}>
													{/* Message */}
													<FormGroup
														as={Col}
														className={`mb-3 position-relative animate__animated ${
															locale
																? 'animate__fadeInLeft'
																: 'animate__fadeInRight'
														} animate__delay-1s`}
														style={{
															'--animate-delay': '1s',
														}}
													>
														<FormLabel
															htmlFor='message'
															className='text-capitalize'
														>
															{t('labels.message', { ns: 'words' })}
														</FormLabel>
														<Field
															id='message'
															as='textarea'
															innerRef={messageRef}
															rows={8}
															style={{
																resize: 'none',
															}}
															placeholder={t('placeholders.message', {
																ns: 'words',
															})}
															autoComplete='off'
															name='message'
															onChange={(event) => {
																handleChange(event);
															}}
															onBlur={handleBlur}
															value={values.message}
															className={`form-control text-capitalize ${
																touched.message && errors.message
																	? 'is-invalid'
																	: ''
															}`}
														/>
														<FormText className='text-muted'>
															<span className={`${locale ? 'me-1' : 'ms-1'}`}>
																{values.message.length}
															</span>
															<span>
																{t('hints.message.length', { ns: 'words' })}
															</span>
														</FormText>
														<ErrorMessage
															component='div'
															name='message'
															className='invalid-feedback'
														/>
													</FormGroup>
												</Row>

												{/* Submit Form */}
												<FormGroup
													className='d-flex justify-content-center mt-3 animate__animated animate__zoomIn animate__delay-1s'
													style={{
														'--animate-delay': '1.25s',
													}}
												>
													<ButtonComponent
														title={
															isSubmitting
																? t('buttons.sending', { ns: 'words' })
																: t('buttons.submitMessage', { ns: 'words' })
														}
														icon={
															isSubmitting ? (
																<Spinner
																	animation='grow'
																	variant='light'
																	size='sm'
																	className={`${locale ? 'me-2' : 'ms-2'}`}
																/>
															) : (
																<></>
															)
														}
														type='submit'
														disabled={isSubmitting ? true : false}
													/>
												</FormGroup>
											</Form>
										)}
									</Formik>
								</Col>
							</Flip>
						</Row>
					</Container>
				</>
			)}
		</section>
	);
};

export default ContactPage;

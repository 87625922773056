import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Offcanvas, Row } from 'react-bootstrap';
import { FaUserCog } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { Link, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { constructURL } from '../helpers/constructURL';
import useUserData from '../hooks/useUserData';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import '../styles/UserAvatarComponent.styles.css';

// Components
import defaultUserAvatar from '../assets/images/default-user.jpg';
import ButtonComponent from './ButtonComponent';
import IconTextComponent from './IconTextComponent';

const UserAvatarComponent = ({ locale }) => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	const location = useLocation();
	const { userData } = useUserData();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message);
				break;
			case 500:
				toast.error(t('errors.default', { ns: 'sentences' }));
				break;
			default:
				toast.error(message);
				break;
		}
	};

	const handleLogout = (language = 'en') => {
		axios({
			method: 'post',
			url: constructURL('logout'),

			headers: {
				locale: language,
				Authorization: userData && `Bearer ${userData.token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((response) => {
				displayToast(response.data.status, response.data.message);
				handleClose();
				localStorage.removeItem('user');
				sessionStorage.removeItem('beforeLogin');
				sessionStorage.removeItem('beforeSignup');
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			})
			.catch((error) => {
				displayToast(500, error.message);
			});
	};

	return (
		userData && (
			<>
				{userData.user.image ? (
					<Image
						fluid
						src={userData.user.image ?? defaultUserAvatar}
						alt='User Avatar'
						className='rounded-circle'
						style={{
							width: '3rem',
							height: '3rem',
							objectFit: 'cover',
							objectPosition: 'top',
							border: '1px solid #9acffa',
							cursor: 'pointer',
						}}
						onClick={handleShow}
					/>
				) : (
					<div
						className='rounded-circle d-flex justify-content-center align-items-center text-uppercase'
						style={{
							width: '3rem',
							height: '3rem',
							objectFit: 'cover',
							objectPosition: 'top',
							color: '#11142d',
							backgroundColor: '#dfe7ff',
							border: '1px solid #9acffa',
							cursor: 'pointer',
						}}
						onClick={handleShow}
					>{`${userData.user.fname[0]}${userData.user.lname[0]}`}</div>
				)}

				<Offcanvas
					dir={locale ? 'ltr' : 'rtl'}
					lang={locale ? 'en' : 'ar'}
					id='user-menu'
					show={show}
					onHide={handleClose}
					placement={locale ? 'start' : 'end'}
				>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title
							className='display-6 fw-bold text-capitalize'
							style={{
								fontSize: '1.8rem',
								color: '#11142d',
							}}
						>
							{t('userProfile', { ns: 'words' })}
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Container>
							<Row xs={1} className='g-4 text-center'>
								<Col>
									{/* User Avatar */}
									<div className='image-container position-relative'>
										<Image
											fluid
											src={userData.user.image ?? defaultUserAvatar}
											alt='User Avatar'
											className='rounded-circle p-1'
											style={{
												width: '10rem',
												height: '10rem',
												border: '4px solid #15a39c',
												objectFit: 'cover',
												objectPosition: 'top',
											}}
										/>
									</div>
								</Col>
								<Col className='d-flex flex-column align-items-center'>
									<h2
										className='m-0 p-0 fw-bold text-capitalize pb-1'
										style={{
											fontSize: '1.5rem',
											color: '#11142d',
										}}
									>{`${userData.user.fname} ${userData.user.lname}`}</h2>
									<IconTextComponent
										icon={<HiOutlineMail size={24} color='rgb(0, 113, 220)' />}
										text={userData.user.email}
										isCapitalized={false}
										textStyles={{
											color: '#808191',
										}}
									/>
									<ButtonComponent
										title={t('logout', { ns: 'words' })}
										className='btn-base-light mt-2'
										styles={{
											button: {
												border: '1px solid rgb(0, 113, 220)',
												padding: '0.25rem 1rem',
											},
										}}
										onClick={() => handleLogout(lang === 'en' ? 'en' : 'ar')}
									/>
								</Col>
							</Row>

							<Row xs={1} className='mt-5'>
								<Col>
									<Link
										to={`/${lang}/settings`}
										target='_top'
										className='profile position-relative overflow-hidden d-flex align-items-center'
										style={{
											textDecoration: 'none',
										}}
										onClick={() => {
											sessionStorage.setItem(
												'beforeSettings',
												location.pathname
											);
											handleClose();
										}}
									>
										<FaUserCog
											size={34}
											color='rgb(0, 113, 220)'
											style={{
												marginRight: locale ? '1rem' : '0',
												marginLeft: locale ? '0' : '1rem',
											}}
										/>
										<div>
											<h6
												className='m-0 p-0 fw-bold text-capitalize'
												style={{
													fontSize: '1.2rem',
													color: 'rgb(0, 113, 220)',
												}}
											>
												{t('myProfile', { ns: 'words' })}
											</h6>
											<div
												style={{
													color: '#808191',
												}}
											>
												{t('accountSettings', { ns: 'words' })}
											</div>
										</div>
									</Link>
								</Col>
							</Row>
						</Container>
					</Offcanvas.Body>
				</Offcanvas>
			</>
		)
	);
};

export default UserAvatarComponent;

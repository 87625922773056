import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// Styles
import '../styles/NoResultsComponent.styles.css';

// Components
import ButtonComponent from './ButtonComponent';

const NoResultsComponent = () => {
	// i18n
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);

		// eslint-disable-next-line
	}, [lang]);

	return (
		<Container
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			lang={lang}
			fluid
			className='text-center d-flex align-items-center text-dark'
			id='no-results'
		>
			<Container fluid className='my-auto'>
				<Row xs={1} className='g-4'>
					<Col className='d-flex flex-column justify-content-center align-items-center p-5 animate__animated animate__fadeInUp'>
						{/* Main Text */}
						<h2 className='display-6 mb-4'>
							{t('errors.noResults', { ns: 'sentences' })}
						</h2>

						{/* Redirect Button To Contact Us */}
						<LinkContainer
							to={`/${lang}/contact-us`}
							target='_top'
							className='text-capitalize'
							style={{
								textDecoration: 'none',
							}}
						>
							<ButtonComponent
								title={t('buttons.contactUs', { ns: 'words' })}
							/>
						</LinkContainer>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default NoResultsComponent;
